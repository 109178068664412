import { useEffect } from "react";
import { bodyClass } from "./bodyClass";

export function Changes() {
  useEffect(() => {
    bodyClass("about");
  }, []);

  return (
    <>
      <h1 className="title is-spaced">Change Log</h1>

      <div className="block">
        <p>
          The Kelly Files Database site came on-line in June, 2018. Here's a log of what's changed since then (in reverse chronological order).
        </p>
      </div>

      <div className="block">
        <h2 className="subtitle">August 22, 2021</h2>
        <p>
          John Milmo provided updates to the Original Series, Unlettered and Suffix-f files, which have now been incorporated into the database.
        </p>
        <p>
          Fixed bug in Advanced Search that caused failure when search involved file name.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">May 30, 2021</h2>
        <p>
          The database has been updated to correct errors discovered by Christian Zwarg, Peter Adamson, Paul Steinson and John Milmo, adding 16 recordings and otherwise improving the search results.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">April 18, 2021</h2>
        <p>
          Several new and modified Kelly files have been incorporated, taking the total number of recordings over 452,000.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">April 9, 2021</h2>
        <p>
          The 0KA file has been added to the collection, yielding an additional 1,101 recordings. 
        </p>
        <p>
          "Matrix numbers" for the Original Series of recordings have been corrected to reflect te day of recording and a daily serial number. 
        </p>
        <p>
          Updated versions of the BL, BK and Unlettered files have been incorporated.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">February 26, 2021</h2>
        <p>
          Some recordings in the 0LA and CTPX files were marked with the wrong recording size. These have now been corrected. 
        </p>
        <p>
          Issues for some recordings were erroneously listed multiple times in search results.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">February 13, 2021</h2>
        <p>
          New titles were added to Suf-o and Suf-a, a format correction was made
          in Suf-L, and the name of a pianist accompanying Elsie Carlisle was
          corrected in the Bb/Cc file as a result of correspondence from a user
          of the site. 
        </p>
        <p>
          A new file prepared by John Milmo, covering mobile van
          recordings, special recordings and private recording made between 1934
          and 1960 has been parsed, and its more than 8,600 recordings added to
          the database.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">November 21, 2020</h2>
        <p>
          Bug fix: A search term that contains a single quote (apostrophe), such
          as "Joseph O'Mara" failed with an ugly crash. Now it doesn't.
        </p>
        <p>
          In many cases, Kelly's files used "smart" quotes instead of simple quotes, and
          the resulting recordings were very difficult to find in a search. Now
          the problematic characters have been replaced with simple quotes.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">November 1, 2020</h2>
        <p>
          Over 7,000 recordings from the HMV Celebrity Series Recordings and
          Historical Reissues 1924-1958 listing (10” records in the DA, DJ, DL
          and DR series, 12” records in the DB, DK, DM, DO, DQ and DS series,
          and reissues in the ABHA, ABHB, AGSA, AGSB, IR, IRX, VA and VB series)
          have been added as 'new' recordings because they began life as Victor
          recordings. 
        </p>
        <p>
          In addition, recordings in that series that began life as
          HMV or Gramophone Co. recordings may have additional issue numbers
          added if the issue number from those series was not already in the
          database.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">September 6, 2020</h2>
        <p>
          Many entries for recordings by Sir John Barbirolli were improved based
          on input from Malcolm Walker.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">August 23, 2020</h2>
        <p>
          John Milmo provided revised versions of the -g/-h/-i files, and
          several new documents listing recordings from 1934, with prefixes
          starting with 0A. As a result, the database has approximately 2,000
          additional recordings. The latest tally is 425,193.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">August 16, 2020</h2>
        <p>
          A replacement for the 0BA/2BA file has been incorporated, with many
          corrections made by John Milmo and John Bolig.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">August 1, 2020</h2>
        <p>
          A replacement for the old Suf-G file has been incorporated. Based on
          scans that were made of the 7” recordings at the EMI Archive, data has
          been added to many of the recording entries in this file, and suffixes
          appended to the matrix numbers, faithfully transcribed from the actual
          recordings. 
        </p>
        <p>
          Many of the matrix numbers have extended suffixes, the
          precise meaning of which can now only be guessed at. Whatever these
          extended suffixes meant, the practice was probably discontinued once
          the matrix numbering was standardised in 1904 (see the first article
          by John Milmo under the Resources tab in this website).
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">July 19, 2020</h2>
        <p>
          Several items have been corrected in the Suf-a, Suf-d and Suf-e files
          and, based on extensive work by John Milmo and Peter Adamson, a
          completely revised version of the Suf- A(D) file has been
          incorporated; all of this is related to some of the earliest
          recordings in the database.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">June 6, 2020</h2>
        <p>
          A few corrections to recording suffixes in the Suf-k file to correctly
          capture early use of suffix C and HP, and a few other corrections
          based on user reports.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">May 24, 2020</h2>
        <p>A few more corrections, based on reports from various people.</p>
      </div>
      <div className="block">
        <h2 className="subtitle">May 10, 2020</h2>
        <p>
          A few corrections have been made, based on reports from various
          people.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">May 2, 2020</h2>
        <p>
          Fixed a bug (reported by Peter Adamson) that caused all issue numbers
          for recordings in the BR.docx file to be dropped. Also corrected a
          couple of errors reported by Enric Giné.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">April 5, 2020</h2>
        <p>A couple of errors reported by Theo Morgan have been corrected.</p>
      </div>
      <div className="block">
        <h2 className="subtitle">March 21, 2020</h2>
        <p>A few errors reported by Philip Stuart have been corrected.</p>
      </div>
      <div className="block">
        <h2 className="subtitle">March 8, 2020</h2>
        <p>
          John Milmo provided an updated BV file, giving us an additional 150 or
          so recordings, for a total of 423,094.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">March 1, 2020</h2>
        <p>
          A few errors in the BF and BT files, discovered by Peter Adamson and
          John Milmo, have been corrected.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">December 30, 2019</h2>
        <p>
          Bug fix: Search criteria based on some (apparently seldom used) fields
          (e.g., file name) were being ignored. Some searches did not find all
          matches when data included accents.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">December 14, 2019</h2>
        <p>The website has been moved to a new server.</p>
      </div>
      <div className="block">
        <h2 className="subtitle">December 11, 2019</h2>
        <p>The basic search function should be somewhat faster.</p>
      </div>
      <div className="block">
        <h2 className="subtitle">November 10, 2019</h2>
        <p>
          A few titles from the BR.docx file were incorrect, now fixed thanks to
          a report from Dr. John Waś.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">October 27, 2019</h2>
        <p>
          Recording sizes for suffix-e and suffix-f recordings were incorrect;
          now they are not.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">October 13, 2019</h2>
        <ol>
          <li>
          Recording sizes (e.g., 7-inch, 10-inch, 12-inch) are now included in
          the details when a recording is selected in the search results. 
          </li>
          <li>
          As a result of some small corrections, a few additional recordings were
          captured, and some spurious false hits dropped. In total, the database
          now has 423,110 recordings. 
          </li>
          <li>
            A problem reported by Peter Adamson, which
          comes up occasionally when database errors occur, has been at least
          partially addressed. Corrected an error that resulted in incorrect
          titles for recordings in a few files that had "dittoed" titles.
          </li>
          <li>
          Handled a few cases in the suffix ae file where second title
          paragraphs started with an asterisk. Fixed bug in parsing of the
          prefix 0f file, reported to John Milmo by Sam Brylawsk.
          </li>
        </ol>
      </div>
      <div className="block">
        <h2 className="subtitle">August 25, 2019</h2>
        <ol>        
          <li>
            Basic Search now allows for variant spellings of [a] performer names and [b] titles / composer names. 
          </li>
          <li>
            Problems parsing the 0F.docx file have been corrected, giving us an extra 1,057 recordings. 
          </li>
          <li>
            Some idiosyncrasies in the am.docx file have been recognized by the parser, yielding another 1200+ recordings. 
          </li>
          <li>
            In total, the database now 423,120recordings. 
          </li>
          <li>
            A few very long titles have been broken into shorter pieces. The automatic counting of results on the search page may sometimes be a bit faster.
          </li>
        </ol>
      </div>
      <div className="block">
        <h2 className="subtitle">June 15, 2019</h2>
        <p>
          Various parsing corrections have removed many duplicate and otherwise
          spurious recordings. At the same time, recordings with takes like
          '-1A', '-2A' and so on are now better handled, and many that had been
          erroneously discarded are now captured. New recording total is
          420,834. Many recordings from the BX file had missing titles, which
          have been recovered.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">June 13, 2019</h2>
        <p>Toronto Raptors win 2019 NBA Championship!</p>
      </div>
      <div className="block">
        <h2 className="subtitle">June 1, 2019</h2>
        <p>
          John Milmo provided an additional file (0ER.docx) which has now been
          taken into the database, adding over 2,000 recordings, and leading to
          a new total of 420,688 recordings.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">May 5, 2019</h2>
        <p>
          An error in the location for some recordings in the ~UNLET file,
          pointed out by Peter Adamson, has been corrected. The database can now
          have up to 5 title lines for a recording (previously only 3). A
          recently-introduced bug that suppressed details of some Issues has
          been fixed.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">April 20, 2019</h2>
        <p>
          Two greatly improved files (suffix ae and suffix ak) provided by John
          Milmo have been incorporated. This, and some parser improvements,
          result in a new total of 417,988 recordings in the database.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">March 19, 2019</h2>
        <p>
          A bug (introduced in the March 17 release) meant that issue numbers
          were not displayed for some recordings. This has now been fixed.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">March 17, 2019</h2>
        <p>
          Improved parsing of the suffix ab file, thanks largely to updates from
          John Milmo. The Resources page has been expanded. The database now has
          a total of 414,515 recordings.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">February 24, 2019</h2>
        <p>
          Improved parsing of the 0BR file, especially with regard to recordings
          with takes like -1A. The database now has a total of 412,586
          recordings.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">February 10, 2019</h2>
        <p>
          Locations for recordings in the BB file have been updated to be more
          complete and accurate. Relay recordings (in the BR and 0BR files) have
          been updated to identify the remote location where the recording took
          place. In the Bb file, a notation about some 1" Doll's House
          recordings had been lost, but is now recovered. The indefatigable John
          Milmo provided a considerably-updated file (BT2.docx, replacing the
          BT.docx file). The database now has a total of 411,240 recordings.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">January 31, 2019</h2>
        <p>
          John Milmo provided a new file (0AB.docx) and one with updates
          (0LA.docx). These having been incorporated, and a correction made to
          the parsing of the 0BR.docx file, the database now has a total of
          411,065 recordings. As requested by Peter Adamson, the Export function
          now also emits, for each recording, the name of the Kelly file it came
          from, the Location where the recording was made, and any Notes the
          database has about the recording.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">January 24, 2019</h2>
        <p>
          Two other files provided by John Milmo (0TB and 0ZA) have been
          incorporated, and the database now holds 401,874 recordings.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">January 21, 2019</h2>
        <p>
          John Milmo provided updated versions of the 0RA and 0VH files. These
          having been incorporated, and a few parsing improvements made, the
          database now has a total of 400,584 recordings, and many errors and
          inaccuracies have been corrected.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">December 31, 2018</h2>
        <p>
          The Feedback page has been updated to reduce the volume of spam being
          generated by malicious "bots".
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">December 29, 2018</h2>
        <p>
          Numerous corrections have been made, especially in parsing (a)
          locations and (b) recordings with multiple title lines. The database
          now has 398,102 recordings.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">December 23, 2018</h2>
        <p>
          The parsing of the Original Series file has been improved, following
          recommendations from Peter Adamson.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">December 17, 2018</h2>
        <p>
          Various improvements have been made, in particular to better capture
          data about operatic works in the MAT304 0M file, and a few errors
          pointed out by users have been corrected.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">December 9, 2018</h2>
        <p>
          Improvements to parsing resulted in an additional 1,912 Recordings
          being captured. We now have 397,999. We fixed a bug that caused any
          basic search qualified by Location to return zero results. A few
          errors pointed out by users have been corrected.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">October 26, 2018</h2>
        <p>
          Parsing of the Kelly files has been somewhat improved, especially with
          respect to the capturing of issue numbers. As a result, we "lost" 5
          recordings that had been collected in error. We now have 396,085. A
          few bugs have been fixed, and the database design has been somewhat
          improved, although that had no effect on the site's operation. We made
          a few minor tweaks to the Search page to improve usability.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">October 6, 2018</h2>
        <p>
          We recently received an additional Kelly file (the one named 0CS.docx)
          and have now incorporated its changes into the database. As a result,
          we now have 396,090 recordings, up from the previous count of 393,640.
          Several improvements to the parsing program have also been made,
          particularly with regard to capturing Issue Numbers.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">September 2, 2018</h2>
        <p>
          Numerous small corrections to the original Kelly files have been made,
          along with improvements to the parsing program, enhancing the accuracy
          of the database, and yielding a new total of 393,640 recordings.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">July 14, 2018</h2>
        <p>
          We recently received an update to one of the Kelly files (the one
          named 0EA.docx) and have now incorporated its changes into the
          database. As a result, we now have 393,130 recordings, up from the
          previous count of 391,778.
        </p>
      </div>
      <div className="block">
        <h2 className="subtitle">June 26, 2018</h2>
        <p>
          The site comes on-line, with a database containing 391,778 recordings.
        </p>
      </div>
    </>
  );
}
