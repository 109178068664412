import { useState } from 'react';
import { TextField } from './TextField';
import { ErrorMessages } from '../ErrorMessages';
import EditApi from '../Api/EditApi';
import { RecordingSizeInput } from './RecordingSizeInput';

export function DeleteRecording({ recording, display, setLoading }) {

    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState(null);

    const [input, setInput] = useState({
        emailAddress: '',
        suggestionNotes: ''
    });

    const submit = () => {
        setLoading(true);

        let body = {
            recordingId: recording.id,
            emailAddress: input.emailAddress, 
            suggestionNotes: input.suggestionNotes           
        }

        EditApi.suggestRemoveRecording(body).then(response => {

            if (!response.ok) {
                console.log("suggestRemoveRecording response not ok", response.status);

                return response.json().then(errors => {

                    setErrors(errors);
                    setLoading(false);
                }).catch(e => {
                    console.log("error response didn't contain json");
                    throw e;
                });
            }

            console.log("suggestRemoveRecording sucess");
            setSubmitted(true);
            setErrors(null);
            setLoading(false);

        }).catch(e => {
            setErrors({ "general": ["An error occured. Please try again later."] });
            setLoading(false);
        });
    }

    const onChange = (name) => {
        return (event) => { setInput(i => ({ ...i, [name]: event.target.value })); };
    };

    if (!display) {
        return null
    }

    const title = "Remove Recording"

    if (submitted) {
        return (
            <>
                <h1 className="title is-spaced">{title}</h1>


                <div className="notification is-success">
                    Thank you for your submission. It will be reviewed shortly.
                </div>
            </>
        );
    }

    let titles = [recording?.title ?? '', recording?.title2 ?? '', recording?.title3 ?? '', recording?.title4 ?? '', recording?.title5 ?? ''].filter(t => t != null && t !== '');

    if (titles.length < 1) {
        titles.push('');
    }

    return (
        <>
            <h1 className="title is-spaced">{title}</h1>

            <ErrorMessages errors={Object.values(errors ?? {}).flat()} />

            <div className="block">
                <div className="columns">

                    <div className="column is-6">
                        <TextField disabled={true} label="Matrix Number" value={recording.matrixNumber} />

                        <TextField disabled={true} label="Prefix" value={recording.prefix} />

                        <TextField disabled={true} label="Suffix" value={recording.suffix} />
                    </div>

                    <div className="column is-6">
                        <TextField disabled={true} label="Take Number" value={recording.takeNumber} />

                        <TextField disabled={true} label="Recording Date" value={recording.recordingDate} />

                        <RecordingSizeInput disabled={true} value={recording.sizeCode} />

                    </div>
                </div>
            </div>

            <div className="block">
                <div className="columns">
                    <div className="column is-6">

                        {titles.map((title, index) => {
                            return (<TextField
                                disabled={true} 
                                label={"Title " + (index + 1)}
                                name={"title[" + index + "]"}
                                value={titles[index]}
                            />);
                        })}
                    </div>
                </div>
            </div>

            <hr />

            <TextField label="Your Email Address" value={input.emailAddress} onChange={onChange("emailAddress")} errors={errors?.EmailAddress} />

            <TextField label="Reason for Change" value={input.suggestionNotes} onChange={onChange("suggestionNotes")} errors={errors?.SuggestionNotes} />

            <div className="field is-grouped">
                <div className="control">
                    <button className="button is-link" onClick={(event) => { submit(); }}>Suggest Remove Recording</button>
                </div>
            </div>
        </>
    );
}
