import { useState, useEffect } from 'react';
import { HelpModal } from './HelpModal';
import { HelpIcon } from "./HelpIcon";
import { bodyClass } from "../bodyClass";
import { useHistory } from 'react-router-dom';

export function AdvancedSearchForm() {

    const defaultInputs = {
        artist: '',
        artistAlt: '',
        title: '',
        titleAlt: '',
        prefix: '',
        suffix: '',
        matrixNumber: '',
        issueNumber: '',
        recordingDate: '',
        location: '',
        filename: '',
        other: '',
        notes: '',
        maxResults: 1000,
    };

    const history = useHistory();    
    const [inputs, setInputs] = useState(history?.location?.state?.inputs || defaultInputs);
    const [displayHints, setDisplayHints] = useState(false);
    const [displayHelpModal, setDisplayHelpModal] = useState(false)

    let search = (criteria) => { 
        let urlSearchParams = new URLSearchParams(criteria); 
        history.replace(history.location.pathname + history.location.search, { inputs: criteria }); 
        history.push(`/advanced_search_results?${urlSearchParams.toString()}`); 
    }
                
    useEffect(() => {
        let bodyClassName = 'advancedsearch'
        bodyClass(bodyClassName)
    }, []);    
    
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
    };

    let searchDisabled = inputs.artist === defaultInputs.artist &&
        inputs.artistAlt === defaultInputs.artistAlt &&
        inputs.title === defaultInputs.title &&
        inputs.titleAlt === defaultInputs.titleAlt &&
        inputs.prefix === defaultInputs.prefix &&
        inputs.suffix === defaultInputs.suffix &&
        inputs.matrixNumber === defaultInputs.matrixNumber &&
        inputs.issueNumber === defaultInputs.issueNumber &&
        inputs.recordingDate === defaultInputs.recordingDate &&
        inputs.location === defaultInputs.location &&
        inputs.filename === defaultInputs.filename &&
        inputs.other === defaultInputs.other &&
        inputs.notes === defaultInputs.notes;
    
    return (
        <>
            <h1 className="title is-spaced">Advanced search</h1>

            <h2 className="subtitle">
                Fill in one or more of your search criteria below. If you enter multiple criteria, only entries that match them all will be returned.
            </h2>

            <HelpModal display={displayHelpModal} setDisplay={setDisplayHelpModal} />

            <div className="block fields" style={{ position: "relative" }}>

                {displayHints && (
                    <div style={{ fontSize: "0.8em", position: "absolute", left: "800px", top: "30px", width: "275px", padding: "20px", backgroundColor: '#63cadd', borderRadius: "0.5em" }}>
                        <h6 className="title is-6" style={{ color: "black" }}>Search for Performers, Composers and Titles containing diacritical marks (accents) or transliterations from languages that do not use the Roman alphabet.</h6>

                        <p>
                            This database does not use any authority control, so unlike library searches and searches made in Google, the system will not flip you to a standard spelling or ask you if you meant another form of the name.
                        </p>

                        <p>
                            It is possible, however, to use the second box, available in the Performer field and the Title field of the Basic Search, to enter a variant form of the name, e.g.,
                        </p>

                        <ol>
                            <li>an alternate Romanization of a Russian  name (e.g., Chaikovsky or Rachmaninoff for Tchaikovsky or Rachmaninov)</li>

                            <li>a form of the name using a diphthong instead of a diacritical mark (e.g., Bjoerling).</li>
                        </ol>

                    </div>
                )}

                <form onSubmit={(event) => { if (!searchDisabled) { search(inputs); } }}>
                
                <div className="field">
                    <label className="label">Performer</label>

                    <div className="columns is-mobile" style={{ alignItems: "center", justifyContent: "center" }}>
                        <div className="column or-desktop">
                            or
                        </div>

                        <div className="column">

                            <div className="control">
                                <div className="columns is-mobile">
                                    <div className="column">
                                        <input autoFocus className="input" type="text" name="artist" value={inputs.artist} onChange={handleInputChange} placeholder="Any part of the performer’s name" />
                                    </div>
                                    <div className="column is-narrow is-hidden-touch" style={{ width: "50px" }}>
                                        <HelpIcon onClick={(event) => { setDisplayHints(hints => !hints); }} />
                                    </div>
                                </div>
                            </div>

                            <div className="column or-mobile">
                                or
                            </div>

                            <div className="control">
                                <div className="columns  is-mobile">
                                    <div className="column">
                                        <input className="input" type="text" name="artistAlt" value={inputs.artistAlt} onChange={handleInputChange} placeholder="Variant spelling of performer’s name" />
                                    </div>
                                    <div className="column is-narrow is-hidden-touch" style={{ width: "50px", flexGrow: 0 }}>
                                        <HelpIcon onClick={(event) => { setDisplayHints(hints => !hints); }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="field">
                    <label className="label">Title or composer</label>

                    <div className="columns" style={{ alignItems: "center", justifyContent: "center" }}>
                        <div className="column or-desktop">
                            or
                        </div>

                        <div className="column">

                            <div className="control">
                                <div className="columns">
                                    <div className="column">
                                        <input className="input" type="text" name="title" value={inputs.title} onChange={handleInputChange} placeholder="Any part of the title or composer’s name" />
                                    </div>
                                    <div className="column is-narrow is-hidden-touch" style={{ width: "50px" }}>
                                        <HelpIcon onClick={(event) => { setDisplayHints(hints => !hints); }} />
                                    </div>
                                </div>
                            </div>

                            <div className="column or-mobile">
                                or
                            </div>

                            <div className="control">
                                <div className="columns">
                                    <div className="column">
                                        <input className="input" type="text" name="titleAlt" value={inputs.titleAlt} onChange={handleInputChange} placeholder="Variant spelling of title or composer" />
                                    </div>
                                    <div className="column is-narrow is-hidden-touch" style={{ width: "50px" }}>
                                        <HelpIcon onClick={(event) => { setDisplayHints(hints => !hints); }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="columns">
                    <div className="column">                    

                        <div className="field">
                            <label className="label">Prefix</label>
                            <div className="control">
                                <input className="input" type="text" name="prefix" value={inputs.prefix} onChange={handleInputChange} placeholder="Alpha or Alpha-numeric prefix" />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Suffix</label>
                            <div className="control">
                                <input className="input" type="text" name="suffix" value={inputs.suffix} onChange={handleInputChange} placeholder="Alpha suffix" />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Matrix #</label>
                            <div className="control">
                                <input className="input" type="text" name="matrixNumber" value={inputs.matrixNumber} onChange={handleInputChange} placeholder="Matrix number without any prefix, suffix or take #" />
                            </div>
                        </div>


                        <div className="field">
                            <label className="label">Issue number</label>
                            <div className="control">
                                <input className="input" type="text" name="issueNumber" value={inputs.issueNumber} onChange={handleInputChange} placeholder="Any part of an issue number" />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Recording date</label>
                            <div className="control">
                                <input className="input" type="text" name="recordingDate" value={inputs.recordingDate} onChange={handleInputChange} placeholder="In Kelly’s numeric format: (dd-mm-yy) e.g. 5-1-99" />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Location</label>
                            <div className="control">
                                <input className="input" type="text" name="location" value={inputs.location} onChange={handleInputChange} placeholder="City, country or venue name" />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">File</label>
                            <div className="control">
                                <input className="input" type="text" name="filename" value={inputs.filename} onChange={handleInputChange} placeholder="Kelly file name" />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Other</label>
                            <div className="control">
                                <input className="input" type="text" name="other" value={inputs.other} onChange={handleInputChange} />
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Notes</label>
                            <div className="control">
                                <input className="input" type="text" name="notes" value={inputs.notes} onChange={handleInputChange} />
                            </div>
                        </div>


                        <div className="block">
                            <div className="columns">
                                <div className="column">
                                    <button 
                                        className="button is-secondary" 
                                        onClick={(event) => { search(inputs); }}
                                        disabled={searchDisabled}
                                    >Search</button>
                                </div>

                                <div className="column">
                                    <div className="columns is-mobile columns-search-buttons">
                                        <div className="column">
                                            <button type="button" className="button is-text" onClick={(event) => { setInputs(defaultInputs); }}>Clear search criteria</button>
                                        </div>

                                        <div className="column">

                                        </div>

                                        <div className="column">
                                            <button type="button" className="button is-text" onClick={(event) => { setDisplayHelpModal(true); }}>Help about search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="column is-narrow is-hidden-touch" style={{ width: "50px" }}>
                        
                    </div>
                </div>
                
                </form>
            </div>

            <div className="block">

                <p>
                    Searches that return a large number of results will be slower, so search results are normally truncated at 1,000. This limit may be changed to any value between 100 and 5,000.
            </p>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Max Results:</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <input className="input" type="text" name="maxResults" value={inputs.maxResults} onChange={handleInputChange} style={{ maxWidth: '80px' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <p>
                    Search results can be exported to a text file that can then be imported to Excel, Google Sheets, and most other spreadsheet programs.
                    Click the Export Results link on the Search Results page to download results (as a tab-delimited text file), and then use your spreadsheet program's "Import from Text file" function.
                </p>
            </div>
        </>
    );
}
