import { useState } from 'react';
import { TextField } from './TextField';
import { ErrorMessages } from '../ErrorMessages';
import EditApi from '../Api/EditApi';


export function MoveToExistingSession({ data, display, setLoading }) {

    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState(null);

    const [input, setInput] = useState({
        sessionId: '',
        emailAddress: '',
        suggestionNotes: '',
    });

    const submit = () => {
        setLoading(true);

        let body = {
            recordingId: data.recording.id,
            sessionId: parseInt(input.sessionId, 10),
            emailAddress: input.emailAddress,
            suggestionNotes: input.suggestionNotes,
        }

        EditApi.suggestMoveRecording(body).then(response => {

            if (!response.ok) {
                console.log("suggestMoveRecording response not ok", response.status);

                return response.json().then(errors => {

                    setErrors(errors);
                    setLoading(false);
                }).catch(e => {
                    console.log("error response didn't contain json");
                    throw e;
                });
            }

            console.log("suggestMoveRecording sucess");
            setSubmitted(true);
            setErrors(null);
            setLoading(false);

        }).catch(e => {
            setErrors({ "general": ["An error occured. Please try again later."] });
            setLoading(false);
        });
    }


    if (!display) {
        return null;
    }

    if (submitted) {
        return (
            <>
                <h1 className="title is-spaced">Move to Another Session</h1>

                <div className="notification is-success">
                    Thank you for your submission. It will be reviewed shortly.
                </div>
            </>
        );
    }

    return (
        <>
            <h1 className="title is-spaced">Move to Another Session</h1>

            <ErrorMessages errors={Object.values(errors ?? {}).flat()} />

            <TextField
                label="Destination Session Id"
                name="Destination Session Id"
                value={input?.sessionId}
                onChange={(event) => {
                    setInput(i => ({ ...i, sessionId: event.target.value }));
                }}
                errors={errors?.SessionId} />

            <hr />

            <TextField label="Your Email Address" value={input.emailAddress} onChange={(event) => setInput(i => ({ ...i, emailAddress: event.target.value }))} errors={errors?.EmailAddress} />

            <TextField label="Reason for Change" value={input.suggestionNotes} onChange={(event) => setInput(i => ({ ...i, suggestionNotes: event.target.value }))} errors={errors?.SuggestionNotes} />

            <div className="field is-grouped">
                <div className="control">
                    <button className="button is-link" onClick={(event) => { submit(); }}>Suggest Session Changes</button>
                </div>
            </div>
        </>
    );
}
