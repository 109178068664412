import arrow_up from '../images/arrow_up.svg';
import arrow_down from '../images/arrow_down.svg';
export function HelpModalSection({ title, displaySection, setDisplaySection, section, children }) {
    const onClickHandler = (event) => {
        let displaySectionResult = displaySection !== section ? section : "";
        setDisplaySection(displaySectionResult);
    };

    let selected = displaySection === section;
    let messageBodyClass = selected ? "" : " is-hidden";

    let img = (<img src={selected ? arrow_up : arrow_down} alt={selected ? "▼" : "▲"} />);

    return (

        <article className="message is-link">
            <div className="message-header" onClick={onClickHandler} style={{ cursor: "pointer", color: "white" }}>
                {title}
                {img}
            </div>
            <div className={"message-body" + messageBodyClass}>
                <div className="content">
                    {children}
                </div>
            </div>
        </article>
    );
}
