import { FieldErrors } from "./FieldErrors";

export function RecordingSizeInput({ value, onChange, errors, disabled}) {

    const recordingSizeOptions = [
        { id: 0, name: "Unknown" },
        { id: 1, name: "Doll House" },
        { id: 2, name: "5-inch" },
        { id: 3, name: "6-inch" },
        { id: 4, name: "7-inch" },
        { id: 5, name: "10-inch" },
        { id: 6, name: "12-inch" },
        { id: 8, name: "17-inch" },
        { id: 7, name: "Unspecified" },
    ];

    return (
        <div className="field">
            <label className="label">Size</label>
            <div className="select">
                <select
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                >
                    {recordingSizeOptions.map(recordingSize => {
                        return (<option key={recordingSize.id} value={recordingSize.id}>{recordingSize.name}</option>);
                    })}
                </select>
            </div>
            <FieldErrors errors={errors} />
        </div>
    );
}
