import { useEffect } from 'react';
import { bodyClass } from "./bodyClass";

export function About() {

    useEffect(() => {
        bodyClass('about')
    }, []);

    return (
        <>
        <h1 className="title  is-spaced">About</h1>

        <div className="block">
        <h2 className="subtitle">Background</h2>
        <p>        
        Starting in 1898, the Gramophone Company used various numbering systems to keep track of all the recordings that were made and a 
        separate numbering system to track the recordings that were issued. The first set of numbers were called matrix numbers and the 
        second set of numbers were called issue or catalogue numbers. The matrix numbers were engraved on the metal stampers and the issue 
        or catalogue numbers appeared on the printed labels that were affixed to the records that were sold. As the business became more 
        successful, the company altered its numbering systems several times to cope with the ever increasing number of recordings and so 
        that the number conveyed information about the recording to those who understood the numbering system.
        </p>
        </div>

        <div className="block">
        <h2 className="subtitle">Dr Alan Kelly</h2>
        <p>        
        Dr. Alan Kelly (1928-2015) spent decades coming to a complete understanding of the file numbering and lettering system used by 
        The Gramophone Company and HMV, and in listing the recordings that were made. It was an extraordinary achievement. He developed an 
        MSWord document for each group of recordings in the numbering system. There are over a hundred files of matrix numbers and several 
        dozen files of issue or catalogue numbers. Some of the files are very large and they vary sufficiently in their internal set-up that 
        it was very difficult to develop a single program to scan them so that a searchable database could be created of the whole. 
        There are over 450,000 recordings in the database as it currently stands. The data all comes from Dr. Kelly’s own files with some 
        additions and corrections done by others since his death in 2015. There may be further additions and corrections made to this database 
        over time, as the completion of the database is a work-in-progress.
        </p>
        </div>

        <div className="block">
        <h2 className="subtitle">Acknowledgments</h2>
        <p>        
        The Gramophone Company Discography was developed by Stephen R. Clarke and Roger Tessier. 
        </p>
        <p>
        Stephen Clarke was the Chair of The Historic Singers Charitable Trust (formerly Historic Masters) and is an Adjunct Professor at the 
        Faculty of Music of the University of Toronto. Stephen Clarke is the Executor of the Estate of John Stratton which has supported the work of 
        Historic Masters in the past and currently supports re-issues of the recordings of early singers on Marston Records. 
        </p>
        <p>
        Roger Tessier is a retired computer coding expert whose work has been essential to this project, especially in the design of parser programs 
        to scan the various Kelly files. All the work on the database project has been done on a volunteer basis.
        </p>
        <p>
        The discography wishes to thank John Milmo, who provided both the EMI Archive Trust and Stephen Clarke with copies of the original lists of 
        recordings created by Dr. Kelly and subsequently has provided many updated versions of those lists. 
        </p>
        <p>
        We also wish to thank John Bolig for allowing the use of data from his listing of the HMV Celebrity Series Recordings and Historical Reissues 1924-1958. 
        </p>
        <p>
        Finally, we wish to acknowledge contributions from the discographic community including Peter Adamson, David Mason, Jolyon Hudson, Paul Cleary and Will Prentice.
        </p>
        </div>

        <div className="block">
        <h2 className="subtitle">EMI Archive Trust</h2>
        <p>                
        Is honoured to partner with Stephen Clarke and Roger Tessier whose generosity and sheer commitment has delivered this ground-breaking 
        searchable discography. 
        The Trust is the custodian of The Gramophone Company’s heritage and its rare recordings. One of the world’s largest and most diverse 
        music and technology archives, covering an extraordinary journey through British and global history, starting with the early recorded 
        music industry and the role that EMI (originally The Gramophone Company) played in the emerging industry’s development and globalisation, 
        from 1897 to 1946.
        </p>
        <p>
        The collection includes artefacts associated with the EMI’s significant achievements in electrical engineering, television, 
        communications including radar, science and medicine, along with the early archives of the HMV music shops and the iconic 
        Abbey Road Studios.
        </p>
        </div>

        </>
    )
}