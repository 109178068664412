

export function search(searchCriteria) {
    var urlSearchParams = new URLSearchParams(searchCriteria);
    var queryString = urlSearchParams.toString();
    return fetch(`${process.env.REACT_APP_API_URL}/api/search?${queryString}`)
}

export function searchExportLink(searchCriteria) {
    var urlSearchParams = new URLSearchParams({...searchCriteria, maxResults: 5000});
    var queryString = urlSearchParams.toString();
    return `${process.env.REACT_APP_API_URL}/api/search/export?${queryString}`;
}

export function getRecording(id) {    
    let url = `${process.env.REACT_APP_API_URL}/api/recordings/${encodeURIComponent(id)}`;
    return fetch(url)
}

export default { search, searchExportLink, getRecording }