import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditApi from './Api/EditApi';
import { useMsal } from '@azure/msal-react';
import RecordingApi from './Api/RecordingApi';
import { TextField } from './Edit/TextField';
import { ErrorMessages } from './ErrorMessages';
import { RecordingSizeInput } from './Edit/RecordingSizeInput';
import { Loading } from './Loading';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';


export function ReviewSuggestion() {
    const { id } = useParams();

    const { instance, accounts } = useMsal();

    const [suggestion, setSuggestion] = useState(null);
    const [recordingDetails, setRecordingDetails] = useState(null);
    const [errors, setErrors] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);


    const submit = (approved) => {
        console.log(`suggestion ${id} approved = ${approved}`)
        
        var request = {
            account: accounts[0]
        };

        setLoading(true);

        instance.acquireTokenSilent(request).then(tokenResponse => {
            
            EditApi.updateSuggestionStatus(tokenResponse.accessToken, id, approved).then((response) => {

                if (!response.ok) {
                    throw Error("An error occurred processing the approval / rejection")
                }

                setSubmitted(true)
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                setErrors(["An error occurred processing the approval / rejection"])
            })
        });    
    }

    const approve = () => { submit(true); };
    const reject = () => { submit(false); };

    useEffect(() => {

        var request = {
            account: accounts[0]
        };

        setLoading(true);

        instance.acquireTokenSilent(request).then(tokenResponse => {

            EditApi.getSuggestionById(tokenResponse.accessToken, id).then((data) => {

                console.log("setRecordingEditCollection", data);
                setLoading(false);
                setSuggestion(data);
            });
        });
    }, [id, accounts, instance]);


    useEffect(() => {

        if (suggestion?.recordingId != null)
        {
            RecordingApi.getRecording(suggestion?.recordingId).then(response => {
                if (!response.ok)
                {
                    throw Error("Error loading recording")
                }
    
                return response.json()
            }).then((data) => {
    
                console.log("setRecordingEditCollection", data);
                setRecordingDetails(data);
            })
            .catch(e => {
                setErrors({ "general": ["An error occured. Please try again later."] });
                setLoading(false);
            });
        }

    }, [suggestion]);


    if (loading) {
        return (<Loading display={loading} />)
    }

    if (submitted) {
        return (
            <>
                <h1 className="title is-spaced">Suggestion Review Complete</h1>


                <NavLink to="/review">View other suggestions</NavLink>
            </>
        );
    }

    if (errors) {
        return (
            <ErrorMessages errors={Object.values(errors ?? {}).flat()} />
        )
    }

    if (suggestion === null) {
        return null;
    }

    if (suggestion.suggestionType === "delete_recording") {
        return (<ReviewDeleteRecording recordingDetails={recordingDetails} suggestion={suggestion} approve={approve} reject={reject} />)
    }
    else if (suggestion.suggestionType === "move_recording") {
        return (<ReviewMoveRecording recordingDetails={recordingDetails} suggestion={suggestion} approve={approve} reject={reject} />)
    }
    else if (suggestion.suggestionType === "move_recording_new") {
        return (<ReviewMoveRecordingNewSession recordingDetails={recordingDetails} suggestion={suggestion} approve={approve} reject={reject} />)
    }    
    else if (suggestion.suggestionType === "add_recording") {
        return (<ReviewAddRecording suggestion={suggestion} approve={approve} reject={reject} />)
    }
    else if (suggestion.suggestionType === "edit_recording") {
        return (<ReviewEditRecording recordingDetails={recordingDetails} suggestion={suggestion} approve={approve} reject={reject} />)
    }

    return null;

}

function IssuesFromRecordingDetails(recording) {
    let issues = recording?.issues?.map(i => i.issueNumber).filter(t => t != null && t !== '') ?? [];

    if (issues.length < 1) {
        issues.push('');
    }

    return issues;
}

function TitlesFromRecordingDetails(recording) {
    let titles = [recording?.title ?? '', recording?.title1 ?? '', recording?.title2 ?? '', recording?.title3 ?? '', recording?.title4 ?? '', recording?.title5 ?? ''].filter(t => t != null && t !== '');

    if (titles.length < 1) {
        titles.push('');
    }

    return titles;
}

function ReviewAddRecording({suggestion, approve, reject}) {

    let issues = [suggestion.issue1, suggestion.issue2, suggestion.issue3, suggestion.issue4, suggestion.issue5].filter(i => i !== null !== '');

    return (
        <>
        <h1 className="title is-spaced">Add Recording Suggestion</h1>

        <RecordingFields recording={suggestion} issues={issues} />

        <TextField
            label="Destination Session Id"
            name="Destination Session Id"
            value={suggestion?.sessionId}
            disabled={true}
            />

        <SuggestionFields suggestion={suggestion} />

        <RejectApproveButtons reject={reject} approve={approve} />

        </>
    );

}

function ReviewMoveRecording({recordingDetails, suggestion, approve, reject}) {

    return (
        <>
        <h1 className="title is-spaced">Move Recording to Another Session Suggestion</h1>

        <RecordingFields recording={recordingDetails?.recording} />

        <TextField
            label="Destination Session Id"
            name="Destination Session Id"
            value={suggestion?.sessionId}
            disabled={true}
            />

        <RejectApproveButtons reject={reject} approve={approve} />

        </>
    );
}

function ReviewMoveRecordingNewSession({recordingDetails, suggestion, approve, reject}) {

    return (
        <>
        <h1 className="title is-spaced">Move Recording to Another Session Suggestion</h1>

        <RecordingFields recording={recordingDetails?.recording} />
   
        <TextField
            label="Session Notes"
            name="session-notes"
            value={suggestion?.sessionNotes}
            disabled={true}
            />

        <TextField
            label="Artist 1"
            name="artist-1"
            value={suggestion?.artist1}
            disabled={true}
            />
        
        <TextField
            label="Artist 2"
            name="artist-2"
            value={suggestion?.artist2}
            disabled={true}
            />

        <TextField
            label="Artist 3"
            name="artist-3"
            value={suggestion?.artist3}
            disabled={true}
            />
        
        <TextField
            label="Artist 4"
            name="artist-4"
            value={suggestion?.artist4}
            disabled={true}
            />

        <TextField
            label="Artist 5"
            name="artist-5"
            value={suggestion?.artist5}
            disabled={true}
            />

        <TextField
            label="Destination Session Group Id"
            name="Destination Session Group Id"
            value={suggestion?.sessionGroupId}
            disabled={true}
            />

        {suggestion?.sessionGroupNotes && (
        <TextField
            label="New Session Group Notes"
            name="New Session Group Notes"
            value={suggestion?.sessionGroupNotes}
            disabled={true}
            />
        )}


        {suggestion?.locationId && (
        <TextField
            label="New Session Group Location Id"
            name="New Session Group Location Id"
            value={suggestion?.locationId}
            disabled={true}
            />
        )}

        {suggestion?.sessionGroupId === null && suggestion?.locationId === null && (
        <>
        <TextField
            label="Venue"
            name="venue"
            value={suggestion?.newLocationVenue}
            disabled={true}
            />
        
        
        <TextField
            label="City"
            name="city"
            value={suggestion?.newLocationCity}
            disabled={true}
            />
        
        <TextField
            label="City"
            name="city"
            value={suggestion?.newLocationCountry}
            disabled={true}
            />

        </>
        )}

        <SuggestionFields suggestion={suggestion} />

        <RejectApproveButtons reject={reject} approve={approve} />

        </>
    );
}

function ReviewEditRecording({recordingDetails, suggestion, approve, reject}) {

        let issues = [suggestion.issue1, suggestion.issue2, suggestion.issue3, suggestion.issue4, suggestion.issue5].filter(i => i !== null !== '');
                   
        return (
            <>
            <h1 className="title is-spaced">Edit Recording Suggestion</h1>
    
            <RecordingFields recording={suggestion} issues={issues} />
    
            <SuggestionFields suggestion={suggestion} />
    
            <RejectApproveButtons reject={reject} approve={approve} />
    
            </>
        );
}

function ReviewDeleteRecording({recordingDetails, suggestion, approve, reject}) {
           
    return (
        <>
        <h1 className="title is-spaced">Delete Recording Suggestion</h1>

        <RecordingFields recording={recordingDetails?.recording} />

        <SuggestionFields suggestion={suggestion} />

        <RejectApproveButtons reject={reject} approve={approve} />

        </>
    );
}

function SuggestionFields({suggestion}) {
    return (
        <>
        <TextField label="Suggestion Author" value={suggestion.emailAddress} disabled={true} />

        <TextField label="Reason for Change" value={suggestion.suggestionNotes} disabled={true} />
        </>
    )

}

function RecordingFields({recording, titles, issues}) {

    titles = titles ?? TitlesFromRecordingDetails(recording)
    issues = issues ?? IssuesFromRecordingDetails(recording)

    return (
        <>
        <div className="block">
            <div className="columns">

                <div className="column is-6">
                    <TextField disabled={true} label="Matrix Number" value={recording?.matrixNumber} />

                    <TextField disabled={true} label="Prefix" value={recording?.prefix} />

                    <TextField disabled={true} label="Suffix" value={recording?.suffix} />
                </div>

                <div className="column is-6">
                    <TextField disabled={true} label="Take Number" value={recording?.takeNumber} />

                    <TextField disabled={true} label="Recording Date" value={recording?.recordingDate} />

                    <RecordingSizeInput disabled={true} value={recording?.sizeCode} />

                </div>
            </div>
        </div>

        <div className="block">
            <div className="columns">
                <div className="column is-12">

                    {titles.map((title, index) => {
                        return (<TextField
                            disabled={true} 
                            label={"Title " + (index + 1)}
                            name={"title[" + index + "]"}
                            value={titles[index]}
                        />);
                    })}
                </div>
            </div>
        </div>

        <div className="block">
            <div className="columns">
                <div className="column is-12">

                    {issues.map((issue, index) => {
                        return (<TextField
                            disabled={true} 
                            label={"Issue " + (index + 1)}
                            name={"issues[" + index + "]"}
                            value={issues[index]}
                        />);
                    })}
                </div>
            </div>
        </div>
        </>
    )
}

function RejectApproveButtons({reject, approve}) {

    return (
        <div className="field is-grouped">
            <div className="control">
                <button className="button is-danger is-outlined" onClick={(event) => { reject(); }}>
                    Reject Suggestion
                </button>
            </div>
            <div className="control">
                <button className="button is-success is-outlined" onClick={(event) => { approve(); }}>
                    Approve Suggestion
                </button>
            </div>
        </div>
    )
}