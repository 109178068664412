
export function HelpIcon({ onClick }) {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%"
        }}>
            <div
                onClick={onClick}
                style={{
                    color: "white", backgroundColor: '#63cadd', borderRadius: '50%', width: '25px', height: '25px',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                }}>
                <div style={{ margin: 0, fontWeight: "bolder", fontSize: "1.0em" }}>?</div>
            </div>
        </div>
    );
}
