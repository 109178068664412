import { useState, useEffect } from 'react';
import { EditRecording } from './EditRecording';
import { EditSession } from './EditSession';
import { MoveToNewSession } from './MoveToNewSession';
import { MoveToExistingSession } from './MoveToExistingSession';
import { AddRecording } from './AddRecording';
import { DeleteRecording } from './DeleteRecording';
import { useParams } from 'react-router-dom';
import { getRecording } from '../Api/RecordingApi';
import { getFiles } from '../Api/FilesApi';
import { Loading } from '../Loading';
import { ErrorMessages } from '../ErrorMessages';
import { TextField } from './TextField';
import './Edit.sass';

const actionTypes = [
    { id: "0", name: "Edit Recording" },
    { id: "1", name: "Edit Session" },
    { id: "4", name: "Move Recording to New Session" },
    { id: "5", name: "Move Recording to Existing Session" },
    { id: "3", name: "Remove Recording" },
    { id: "2", name: "Add Recording" },
]

export function Edit() {

    const { id } = useParams();
    const [action, setAction] = useState("0")

    const [data, setData] = useState(null);
    const [files, setFiles] = useState({ items: [] });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        setErrors(null);
        setLoading(true);
        getRecording(id).then((response) => {
            if (!response.ok) {
                throw Error("Error loading recording")
            }

            return response.json();
        }).then((data) => {
            setData(data);            
            setLoading(false);
        })
            .catch(e => {
                console.log(`Error loading recording ${id}`)
                setErrors({ general: ["Could not load recording"]})
                setData(null);
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        getFiles().then((response) => {
            if (!response.ok) {
                throw Error("Error loading file list")
            }

            return response.json();
        }).then((data) => {
            setFiles(data);
        })
            .catch(e => {
                console.log(`Error loading file list`)
                setErrors({ general: ["Could not load file list"]})
                setFiles(null);
            });
    }, []);

    return (
        <>
        
    <div className="field">
        <label className="label">Action</label>
        <div className="select">
            <select                 
                value={action}
                onChange={(event) => { 
                    setAction(event.target.value);
                }}
            >
                {actionTypes.map(actionType => {
                    return (<option key={actionType.id} value={actionType.id}>{actionType.name}</option>)
                })}
            </select>
        </div>
    </div>

    <hr />

    <Loading display={loading} />

    <ErrorMessages errors={Object.values(errors ?? {}).flat()} />


    <EditRecording recording={data?.recording} display={action === "0" && !loading && data != null} setLoading={setLoading} />
    <EditSession data={data} files={files} display={action === "1" && !loading && data != null} setLoading={setLoading} />
    <AddRecording files={files} display={action === "2" && !loading} setLoading={setLoading} />
    <DeleteRecording recording={data?.recording} display={action === "3" && !loading && data != null} setLoading={setLoading}  />
    <MoveToNewSession data={data} files={files} display={action === "4" && !loading && data != null} setLoading={setLoading} />
    <MoveToExistingSession data={data} display={action === "5" && !loading && data != null} setLoading={setLoading} />
    </>
    )
}

