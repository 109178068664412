import { useState } from 'react';
import { TextField } from './TextField';
import { ErrorMessages } from '../ErrorMessages';
import { SessionFields, SessionGroupFields } from './EditSession';
import EditApi from '../Api/EditApi';


export function MoveToNewSession({ data, files, display, setLoading }) {

    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState(null);

    const [input, setInput] = useState({
        emailAddress: '',
        suggestionNotes: '',
        sessionId: 0,
        sessionGroupId: 0,
        artists: [''],
        sessionNotes: '',        
        sessionGroupNotes: '',        
        sessionGroupEditType: "new",
        locationEditType: "new",
        newLocation: {
            venue: '',
            city: '',
            country: ''
        },
        filename: ''
    });

    const submit = () => {
        setLoading(true);

        var newSessionGroup = input?.sessionGroupEditType === "new";
        var currentSessionGroup = input?.sessionGroupEditType === "current";
        var newLocation = (input?.locationEditType === "new") && newSessionGroup;
        var currentLocation = (input?.locationEditType === "current") && newSessionGroup;

        var sessionGroupId = parseInt(input?.sessionGroupId, 10);
        
        if (currentSessionGroup) {
            sessionGroupId = data.sessionGroup.id;
        }
        else if (newSessionGroup) {
            sessionGroupId = null;
        }

        var locationId = parseInt(input?.locationId, 10);

        if (currentLocation) {
            locationId = data.location.id;
        }

        var fileId = parseInt(input?.fileId, 10);

        let body = {
            emailAddress: input.emailAddress,
            suggestionNotes: input.suggestionNotes,
            recordingId: data.recording.id,
            sessionGroupEditType: input?.sessionGroupEditType,
            locationEditType: input?.locationEditType,

            artist1: input.artists?.[0] ?? null,
            artist2: input.artists?.[1] ?? null,
            artist3: input.artists?.[2] ?? null,
            artist4: input.artists?.[3] ?? null,
            artist5: input.artists?.[4] ?? null,
            sessionNotes: input.sessionNotes ?? null,
            sessionGroupId: sessionGroupId,
            sessionGroupNotes: newSessionGroup ? input.sessionGroupNotes : null,
            locationId: newLocation ? null : locationId,
            locationCity: newLocation ? input.newLocation.city : null,
            locationCountry: newLocation ? input.newLocation.country : null,
            locationVenue: newLocation ? input.newLocation.venue : null,
            fileId: fileId
        }

        EditApi.suggestMoveRecordingNewSession(body).then(response => {

            if (!response.ok) {
                console.log("suggestMoveRecording response not ok", response.status);

                return response.json().then(errors => {

                    setErrors(errors);
                    setLoading(false);
                }).catch(e => {
                    console.log("error response didn't contain json");
                    throw e;
                });
            }

            console.log("suggestMoveRecording sucess");
            setSubmitted(true);
            setErrors(null);
            setLoading(false);

        }).catch(e => {
            setErrors({ "general": ["An error occured. Please try again later."] });
            setLoading(false);
        });
    }

    const onChange = (name) => {
        return (event) => { setInput(i => ({ ...i, [name]: event.target.value })); };
    };


    if (!display) {
        return null;
    }

    if (submitted) {
        return (
            <>
                <h1 className="title is-spaced">Move to New Session</h1>

                <div className="notification is-success">
                    Thank you for your submission. It will be reviewed shortly.
                </div>
            </>
        );
    }

    return (
        <>
            <h1 className="title is-spaced">Move to New Session</h1>

            <ErrorMessages errors={Object.values(errors ?? {}).flat()} />


            <SessionFields input={input} setInput={setInput} errors={errors} onChange={onChange} sessionFieldName={"session"} />

            <div className="field">
                <label className="label">Session Group</label>
                <div className="select">
                    <select
                        value={input.sessionGroupEditType}
                        onChange={onChange("sessionGroupEditType")}
                    >
                        <option value="new">Use New Session Group</option>
                        <option value="current">Use Current Session Group</option>
                        <option value="existing">User Another Session Group</option>
                    </select>
                </div>
            </div>
   

            {(input?.sessionGroupEditType === "existing") && (
                <TextField
                    label="Session Group Id"
                    name="Session Group Id"
                    value={input?.sessionGroupId}
                    onChange={(event) => {
                        setInput(i => ({ ...i, sessionGroupId: event.target.value }));
                    }}
                    errors={errors?.SessionGroupId} />
            )}

            {(input?.sessionGroupEditType === "new") && (
                <SessionGroupFields input={input} setInput={setInput} errors={errors} files={files} onChange={onChange} />        
            )}

            <hr />   

            <TextField label="Your Email Address" value={input.emailAddress} onChange={(event) => setInput(i => ({ ...i, emailAddress: event.target.value }))} errors={errors?.EmailAddress} />

            <TextField label="Reason for Change" value={input.suggestionNotes} onChange={(event) => setInput(i => ({ ...i, suggestionNotes: event.target.value }))} errors={errors?.SuggestionNotes} />

            <div className="field is-grouped">
                <div className="control">
                    <button className="button is-link" onClick={(event) => { submit(); }}>Suggest Session Changes</button>
                </div>
            </div>
        </>
    );
}
