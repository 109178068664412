import { useState, useEffect } from 'react';
import { bodyClass } from "./bodyClass";
import { SearchForm } from "./SearchForm";
import { SearchResults } from "./SearchResults";
import { useHistory, useLocation } from 'react-router-dom';
import { search } from './Api/RecordingApi'
import { Loading } from './Loading';

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function HomeResults() {

    useEffect(() => {
        bodyClass('home');
    }, []);

    const history = useHistory();
    const query = useQuery();

    const [results, setResults] = useState(history?.location?.state?.results || null);
    const [searchCriteria, setSearchCriteria] = useState(history?.location?.state?.searchCriteria || null)
            

    useEffect(() => {        
        
        if (results == null) {  

            let q = query.get("query")
            console.log(`searching ${q}`)
            let criteria = { query: q }
                                
            return search(criteria)
                .then(response => {

                    if (!response.ok) {
                        let errorMessage = (response.status ?? "") + " An error occured when searching."
    
                        return { error: errorMessage }
                    }

                    return response.json()
                })                    
                .then(data => {
                    console.log('setting location', history.location);
                    console.log('setting search', history.location.search);
                    console.log('setting path', history.location.pathname);
                    console.log('setResults', data);
                    setResults(data);                    
                    //console.log('setting criteria', criteria);
                    setSearchCriteria(criteria)
                    //history.replace(history.location.pathname + history.location.search, { results: data })
                }).catch((reason) => {
                    setResults({});
                });
        }

    }, [query, searchCriteria, results, history])


    return (
        <>       
        <Loading display={results == null} />

        {((results?.error ?? null) !== null) && (
        <div className="notification is-warning">  
            {results?.error}
        </div>
        )}

        <SearchResults
            searchCriteria={searchCriteria}      
            results={results} 
            totalCount={results?.length} />
        </>
    )

}

export function Home() {

    const history = useHistory();

    let searchCriteria = history?.location?.state?.searchCriteria || null;
    
    useEffect(() => {
        bodyClass('home');
    }, []);
    
    return (
        <>
        <SearchForm 
            searchCriteria={searchCriteria}
            setSearchCriteria={(criteria) => {  
                history.replace(history.location.pathname + history.location.search, { searchCriteria: criteria }); 
                history.push(`/search?query=${criteria.query}`) 
            }} 
            results={null} />
        </>
    );
}
