import logo from './images/the_gramophone_company_discography_logo.png';
import './App.sass';

import CookieConsent, { Cookies } from "react-cookie-consent";
import * as ReactGA from "react-ga";
import usePageTracking from "./usePageTracking"

import {
    BrowserRouter as Router,
    Switch,
    Route,    
    NavLink,
    useLocation
  } from "react-router-dom";

import { Home, HomeResults } from './Home';
import { AdvancedSearchForm } from './AdvancedSearch/AdvancedSearchForm';
import { AdvancedSearchResults } from './AdvancedSearch/AdvancedSearchResults';
import { About } from './About';
import { Changes } from './Changes';
import { Resources } from './Resources';
import { Feedback } from './Feedback';
import { Edit } from './Edit/Edit';
import { ReviewRecordingEdit } from './ReviewRecordingEdit';
import { ReviewSuggestion } from './ReviewSuggestion';
import { Review } from './Review';
import { Login } from './Login';
import { msalApp } from './msal/msalConfig';
import { RoleTemplate } from './msal/RoleTemplate'
import { MsalProvider } from '@azure/msal-react';

const toggleBurger = () => {
    let burgerIcon = document.getElementById('burger');
    let dropMenu = document.getElementById('navbar-menu');
    let section = document.getElementById('main-section');
    let footer = document.getElementById('footer')
    burgerIcon.classList.toggle('is-active');
    dropMenu.classList.toggle('is-active');
    section.classList.toggle('is-active');    
    footer.classList.toggle('is-active');
};

const handleAcceptCookie = () => {
    // google analytics cookies
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
};

const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
};

function App() {

    usePageTracking();
  
    const { pathname } = useLocation();

  return (
    <>        
        <nav className="navbar is-primary" role="navigation" aria-label="main navigation">

            <div className="navbar-brand">                
                {
                // eslint-disable-next-line   
                (<a role="button" 
                    id="burger"
                    className="navbar-burger burger is-large" 
                    aria-label="menu" 
                    aria-expanded="false" 
                    data-target="navbarBasicExample" 
                    onClick={toggleBurger}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
                )}
            </div>

            <NavLink exact={true} to="/" className="navbar-item-logo is-hidden-desktop">
                <img src={logo} alt="The Gramophone Co. Discography" className="logo" />
            </NavLink>
                        
            <div className="nav-container is-max-desktop">
                <div id="navbar-menu" className="navbar-menu">
                    <div className="navbar-start">
                        
                        <NavLink 
                            className="navbar-item" 
                            activeClassName="is-active" 
                            exact={true} 
                            onClick={toggleBurger} 
                            to="/"
                            isActive={ () => { return ['/', '/search'].includes(pathname) } }>
                            Home
                        </NavLink>

                        <NavLink 
                            className="navbar-item" 
                            activeClassName="is-active" 
                            exact={false} 
                            onClick={toggleBurger} 
                            to="/advanced_search" 
                            isActive={ () => { return ['/advanced_search', '/advanced_search_results'].includes(pathname) } }>
                            Advanced Search
                        </NavLink>

                        <NavLink 
                            className="navbar-item" 
                            activeClassName="is-active" 
                            exact={true} 
                            onClick={toggleBurger} 
                            to="/about">
                            About
                        </NavLink>

                        <NavLink 
                            className="navbar-item" 
                            activeClassName="is-active" 
                            exact={true} 
                            onClick={toggleBurger} 
                            to="/resources">
                            Resources
                        </NavLink>

                        <NavLink 
                            className="navbar-item" 
                            activeClassName="is-active" 
                            exact={true} 
                            onClick={toggleBurger} 
                            to="/feedback">
                            Feedback
                        </NavLink>

                        <NavLink 
                            className="navbar-item" 
                            activeClassName="is-active" 
                            exact={true} 
                            onClick={toggleBurger} 
                            to="/changes">
                            Change Log
                        </NavLink> 
                        
                        <RoleTemplate role="gramophone.client.reviewer">
                            <NavLink 
                                className="navbar-item" 
                                activeClassName="is-active" 
                                exact={true} 
                                onClick={toggleBurger} 
                                to="/review">
                                Review Changes
                            </NavLink>    
                        </RoleTemplate>
                    </div>        
                </div>
            </div>
        </nav>
        
        <section id="main-section" className="section">
            <div className="container is-max-desktop">
                <NavLink exact={true} to="/">
                    <img src={logo} alt="The Gramophone Co. Discography" className="logo is-hidden-touch" />
                </NavLink>
                
                <Switch>

                    <Route path="/advanced_search_results">
                        <AdvancedSearchResults />
                    </Route>
                    <Route path="/advanced_search">
                        <AdvancedSearchForm />
                    </Route>

                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/resources">
                        <Resources />
                    </Route>
                    <Route path="/feedback">
                        <Feedback />
                    </Route>                    
                    <Route path="/search">
                        <HomeResults />
                    </Route>
                                        
                    <Route path="/changes">
                        <Changes />
                    </Route>

                    <Route path="/edit/:id">
                        <Edit />
                    </Route>

                    <Route path="/login">
                        <Login />
                    </Route>

                    <Route path="/" exact={true} >
                        <Home />
                    </Route>
                                        
                    <RoleTemplate role="gramophone.client.reviewer">
                        <Route path="/review/:id" exact={true}>
                            <ReviewSuggestion />
                        </Route>

                        <Route path="/review" exact={true}>
                            <Review />
                        </Route>
                    </RoleTemplate>

                    

                                        
                </Switch>
            </div>
        </section>

        <footer id="footer" className="footer">
        <div className="container is-max-desktop">
            <p>
                © Copyright {new Date().getFullYear()} Gramophone Co. Discography, Stephen R. Clarke, Roger Tessier &amp; EMI Archive Trust. 
                All rights reserved.
            </p>            
        </div>
        </footer>

        <CookieConsent 
            debug={true}
            disableButtonStyles={true}
            style={{ padding: '50px' }}
            enableDeclineButton={true}
            declineButtonText="Decline" 
            declineButtonClasses='button is-primary'            
            declineButtonStyle={{ backgroundColor: 'transparent', marginRight: '50px'}}
            buttonText="Accept" 
            buttonClasses='button is-secondary'
            onDecline={handleDeclineCookie}
            onAccept={handleAcceptCookie}            
        >
            <p>
                We are passionate about music. To help us share that passion we'd like to use cookies and similar technologies to personalize your experiences on our sites and to advertise on other sites. 
            </p>
            
            <p>
                By clicking Accept or continuing to use the site, you agree to our use of analytics, marketing, and advertising cookies. 
            </p>                        
        </CookieConsent>
    </>
  );
}

function WrappedApp() {
    return (
        <MsalProvider instance={msalApp}>
            <Router>
                <App />
            </Router>
        </MsalProvider>)
}



export default WrappedApp;
