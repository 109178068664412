import { useState } from 'react';
import { Link } from 'react-router-dom'

export function SearchForm({search, searchCriteria, setSearchCriteria, results}) {
    
    const [query, setQuery] = useState(searchCriteria?.query ?? '');

    if (results !== null) {
        return null
    }
    
    let searchDisabled = query.trim() === "";
    
    return (
    <>    
        <h1 className="title is-spaced">Welcome</h1>
        <h2 className="subtitle">
            The Gramophone Company Discography is a searchable database of recordings made by the Gramophone Company, and its successor corporations during the 78 RPM era beginning in 1898.
        </h2>

        <div className="block">
            The database will be invaluable to researchers, historians and scholars interested in the details of historical recordings, both published and unpublished.
        </div>

        <div className="block">
            The discography, created and built by Stephen Clarke and Roger Tessier, is based on the detailed long-term research of globally renowned discographer, Dr Alan Kelly.
        </div>

        <div className="block">            
            The discography is an evolving and collaborative project and remains to some extent a work-in-progress. We value you feedback. If you have any questions, please <Link to="/feedback">contact us</Link>.
        </div>

        <form onSubmit={(event) => { event.preventDefault(); if (!searchDisabled) { setSearchCriteria({query: query}) } }}>

            <div className="columns">
                <div className="column">
                    <input 
                        autoFocus={true}
                        className="input" 
                        type="text" 
                        placeholder="Search for a performer’s name or variant" 
                        value={query} 
                        onChange={(event) => { setQuery(event.target.value)}} 
                    />

                </div>
                
                <div className="column">
                    <button 
                        className="button is-secondary" 
                        disabled={searchDisabled} 
                        onClick={(event) => { setSearchCriteria({query: query})}}
                    >Search</button>
                </div>
                <div className="column is-hidden-desktop">
                    <Link to="/advanced_search">
                        <button type="button" className="button is-text advanced-search">Advanced Search</button>
                    </Link>
                </div>
            </div>

            <div className="columns is-hidden-touch">
                <div className="column advanced-search-column">
                    <Link to="/advanced_search">
                        <button type="button" className="button is-text advanced-search">Advanced Search</button>
                    </Link>
                </div>
                <div className="column">

                </div>
            </div>

        </form>
    
        <h2 className="subtitle is-4">Searching the database</h2>
    
        <div className="block">
            Because the Kelly files were originally unstructured Word documents, it was difficult to extract all the information in them into discrete fields and impossible to arrive at consistent 
            and standardised forms for names of performers and titles. Every effort has been made to design searches so that they retrieve the recordings sought.
        </div>

        <div className="block">
            <strong>The database contains the following searchable fields:</strong>       
        </div>

        <div className="block">
            <ol>
                <li><strong>Performer(s):</strong> names of one or more performers in various forms</li>
                <li><strong>Title:</strong> names of works or titles of individual pieces as entered by Kelly</li>
                <li><strong>Matrix No:</strong> The Matrix No. without any prefix, suffix, take number or fractional take number</li>
                <li><strong>Prefix to the Matrix No.:</strong> alpha or alpha-numeric prefix</li>
                <li><strong>Suffix to the Matrix No.:</strong> alpha suffix</li>
                <li><strong>Issue Number:</strong> the record company’s assigned catalogue number(s)</li>
                <li><strong>Recording Date:</strong> in numeric form, in the order: dd-mm-yy</li>
                <li><strong>Location:</strong> a city, country or recording venue</li>
                <li><strong>File:</strong> Kelly file name</li>
                <li><strong>Other:</strong> any data that could not be associated with a particular field by the scanning program</li>
                <li><strong>Notes:</strong> when data elements seem to have been mis-situated, they are sometimes explicitly removed and placed in this field.</li>
            </ol>
        </div>       
    </>
    )
}