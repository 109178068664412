import * as msal from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: '846f0b83-b7a4-46cd-be1a-c02c7017bb06',
        authority: `https://login.microsoftonline.com/bbcb6b2f-8c7c-4e24-86e4-6c36fed00b78`,
        redirectUri: `${window.location.origin}/auth.html`,
        //postLogoutRedirectUri: 'https://account.activedirectory.windowsazure.com/r#/applications'
    },
    cache: {
        cacheLocation: 'localStorage'
    },
};

const msalApp = new msal.PublicClientApplication(msalConfig);


const loginRequest = {        
    scopes: ["api://6e5439ff-92c9-44bc-bb4b-f15914699842/Data.Access"]
}

export { msalApp, loginRequest }