export function suggestNewRecording(body) {    
    let url = `${process.env.REACT_APP_API_URL}/api/suggestions/recordings`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions);
}

export function suggestRemoveRecording(body) {    
    let url = `${process.env.REACT_APP_API_URL}/api/suggestions/recordings/${encodeURIComponent(body.recordingId)}/remove`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions);
}


export function suggestMoveRecording(body) {    
    let url = `${process.env.REACT_APP_API_URL}/api/suggestions/recordings/${encodeURIComponent(body.recordingId)}/move`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions);
}

export function suggestMoveRecordingNewSession(body) {    
    let url = `${process.env.REACT_APP_API_URL}/api/suggestions/recordings/${encodeURIComponent(body.recordingId)}/move/new`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions);
}

export function suggestEditRecording(body) {    
    let url = `${process.env.REACT_APP_API_URL}/api/suggestions/recordings/${encodeURIComponent(body.recordingId)}`;

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions);
}

export function suggestEditSession(body) {    
    let url = `${process.env.REACT_APP_API_URL}/api/suggestions/sessions/${encodeURIComponent(body.sessionId)}`;

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions);
}

export function submitEdit(recordingEdits) {    
    let url = `${process.env.REACT_APP_API_URL}/api/edits/${recordingEdits.id}`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(recordingEdits)
    };

    return fetch(url, requestOptions);
}

export function updateSuggestionStatus(accessToken, suggestionId, approved) {    
    let url = `${process.env.REACT_APP_API_URL}/api/suggestions/${suggestionId}/status`;

    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken 
        },
        body: JSON.stringify(approved)
    };

    return fetch(url, requestOptions);
}

export function getSuggestions(accessToken) {  
    
    const requestOptions = {
        method: 'GET',
        headers: {             
            'Authorization': 'Bearer ' + accessToken 
        },        
    };

    let url = `${process.env.REACT_APP_API_URL}/api/suggestions`;

    return fetch(url, requestOptions);
}

export function getSuggestionById(accessToken, id) {   
    
    const requestOptions = {
        method: 'GET',
        headers: {             
            'Authorization': 'Bearer ' + accessToken 
        },        
    };

    let url = `${process.env.REACT_APP_API_URL}/api/suggestions/${id}`;

    return fetch(url, requestOptions).then(response => response.json());
}


const EditApi = { submitEdit, updateSuggestionStatus, getSuggestions, getSuggestionById, suggestNewRecording, suggestEditRecording, suggestEditSession, suggestRemoveRecording, suggestMoveRecording, suggestMoveRecordingNewSession }

export default EditApi