export function ErrorMessages({ errors }) {

    if (((errors ?? null) === null) || (errors.length === 0)) {
        return null;
    }

    let uniqueErrors = [...new Set(errors)];

    return (<article className="message is-danger">
        <div className="message-body">
            <ul>
                {uniqueErrors?.map(error => (<li key={error}>{error}</li>))}
            </ul>
        </div>
    </article>);
}
