import { useState, useEffect } from 'react';

import EditApi from '../Api/EditApi';

import { ErrorMessages } from '../ErrorMessages';

import { TextField } from './TextField';
import { FieldErrors } from './FieldErrors';


export function EditSession({display, data, files, setLoading}) {

    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState(null);
    
    const [input, setInput] = useState({
        emailAddress: '',
        suggestionNotes: '',
        sessionGroupEditType: "current",
        locationEditType: "current",
        sessionId: 0,
        sessionGroupId: '',
        artists: [''],
        sessionNotes: '',        
        sessionGroupNotes: '',        
        locationId: "",
        newLocation: {
            venue: '',
            city: '',
            country: ''
        },
        location: {
            venue: '',
            city: '',
            country: ''
        },
        fileId: 0
    });

    useEffect(() => {
                
        setInput({
            ...input,
            emailAddress: '',
            suggestionNotes: '',
            sessionId: data?.session?.id ?? 0,
            artists: data?.session?.artists ?? [''],
            sessionNotes: data?.session?.notes,            
            sessionGroupId: data?.sessionGroup?.id ?? 0,
            sessionGroupNotes: data?.sessionGroup?.notes,
            currentLocationId: data?.location?.id,
            location: {                
                venue: data?.location?.venue ?? '',
                city: data?.location?.city ?? '',
                country: data?.location?.country ?? ''
            },
            fileId: data?.file?.id ?? 0            
        });

    }, [data]);

    const submit = () => {
        setLoading(true);

        var location = null;
        var locationId = null;        

        var newSessionGroup = input?.sessionGroupEditType === "new";
        var currentSessionGroup = input?.sessionGroupEditType === "current";
        var existingSessionGroup = input?.sessionGroupEditType === "existing";

        var newLocation = input?.locationEditType === "new" && (!existingSessionGroup);
        var currentLocation = input?.locationEditType === "current" && (!existingSessionGroup);
        var existingLocation = input?.locationEditType === "existing" && (!existingSessionGroup);

        if (newLocation) {
            location = input.newLocation
            locationId = null;
        }
        else if (currentLocation) {
            location = input.location;
            locationId = input?.currentLocationId;
        }
        else if (existingLocation) {
            location = null;
            locationId = parseInt(input?.locationId, 10);
        }
        

        var sessionGroupId = null;
        var sessionGroupNotes = null;

        if (currentSessionGroup) {
            sessionGroupId = data.sessionGroup.id;
            sessionGroupNotes = input?.sessionGroupNotes;
        }
        else if (newSessionGroup) {
            sessionGroupId = null;
            sessionGroupNotes = input?.sessionGroupNotes;
        }
        else {
            sessionGroupId = parseInt(input?.sessionGroupId, 10) ?? -1;
            sessionGroupNotes = null;
            location = null;
            locationId = null;
        }

        var fileId = parseInt(input?.fileId, 10);
        
        var body = {
            emailAddress: input.emailAddress,
            suggestionNotes: input.suggestionNotes,
            sessionGroupEditType: input.sessionGroupEditType,
            locationEditType: input.locationEditType,
            sessionId: input?.sessionId,
            artist1: input?.artists?.[0] ?? null,
            artist2: input?.artists?.[1] ?? null,
            artist3: input?.artists?.[2] ?? null,
            artist4: input?.artists?.[3] ?? null,
            artist5: input?.artists?.[4] ?? null,
            sessionNotes: input.sessionNotes,
            sessionGroupId: sessionGroupId,
            sessionGroupNotes: sessionGroupNotes,
            locationId: locationId,
            locationVenue: location?.venue,
            locationCity: location?.city,
            locationCountry: location?.country,
            fileId: fileId,
        }

        EditApi.suggestEditSession(body).then(response => {

            if (!response.ok) {
                console.log("submitEdit response not ok", response.status);

                return response.json().then(errors => {

                    setErrors(errors);
                    setLoading(false);
                }).catch(e => {
                    console.log("error response didn't contain json");
                    throw e;
                });
            }

            console.log("recording edited");
            setSubmitted(true);
            setErrors(null);
            setLoading(false);

        }).catch(e => {
            setErrors({ "general": ["An error occured. Please try again later."] });
            setLoading(false);
        });
    };

    if (submitted) {
        return (
            <>
                <h1 className="title is-spaced">Edit Session</h1>


                <div className="notification is-success">
                    Thank you for your submission. It will be reviewed shortly.
                </div>
            </>
        );
    }

    const onChange = (name) => {
        return (event) => { setInput(i => ({ ...i, [name]: event.target.value })); };
    };


    if (!display) {
        return null
    }

    return (
        <>
            <h1 className="title is-spaced">Edit Session</h1>

            <ErrorMessages errors={Object.values(errors ?? {}).flat()} />

            <SessionFields input={input} setInput={setInput} errors={errors} onChange={onChange} />        

            <div className="field">
                <label className="label">Session Group</label>
                <div className="select">
                    <select
                        value={input.sessionGroupEditType}
                        onChange={onChange("sessionGroupEditType")}
                    >
                        <option value="current">Edit Current Session Group</option>
                        <option value="existing">Move to Another Session Group</option>
                        <option value="new">Move to New Session Group</option>
                    </select>
                </div>
            </div>
   

            {(input?.sessionGroupEditType === "existing") && (
                <TextField
                    label="Destination Session Group Id"
                    name="Destination Session Group Id"
                    value={input?.sessionGroupId}
                    onChange={(event) => {
                        setInput(i => ({ ...i, sessionGroupId: event.target.value }));
                    }}
                    errors={errors?.SessionGroupId} />
            )}

            {(input?.sessionGroupEditType !== "existing") && (
            <SessionGroupFields input={input} setInput={setInput} errors={errors} onChange={onChange} files={files} currentLocationEditable={true} />        
            )}

            <hr />

            <TextField label="Your Email Address" value={input.emailAddress} onChange={onChange("emailAddress")} errors={errors?.EmailAddress} />

            <TextField label="Reason for Change" value={input.suggestionNotes} onChange={onChange("suggestionNotes")} errors={errors?.SuggestionNotes} />
            
            <div className="field is-grouped">
                <div className="control">
                    <button className="button is-link" onClick={(event) => { submit(); }}>Suggest Session Changes</button>
                </div>
            </div>
        </>
    );
}


export function SessionFields({input, setInput, errors, onChange}) {

    return (
        <>
        
        <div className="block">
            <div className="columns">                    
                    <div className="column is-12">

                    <TextField
                        label="Session Notes"
                        name="notes"
                        value={input?.sessionNotes}
                        onChange={(event) => {
                            setInput(i => ({...i, sessionNotes: event.target.value}));
                        }}
                        errors={errors?.SessionNotes} />                    

                    {input?.artists?.map((artist, index) => {
                        return (<TextField
                            label={"Artist " + (index + 1)}
                            name={"artists[" + index + "]"}
                            value={input.artists[index]}
                            onChange={(event) => {
                                setInput(i => {
                                    let newArtists = [...i.artists];
                                    newArtists[index] = event.target.value;
                                    return ({ ...i, artists: newArtists});
                                });
                            }}
                            errors={errors?.["Artist" + (index + 1)]}
                            />);
                    })}

                    {input?.artists?.length < 5 && (
                        <button 
                            className="button is-success mb-1"
                            onClick={(event) => {
                                setInput(i => {
                                    let newArtists = [...i.artists, ''];
                                    return ({ ...i, artists: newArtists});
                                });
                            }}
                        >Add Artist</button>
                    )}  
                </div>                                    
            </div>
        </div>
        
        </>
    )

}

export function SessionGroupFields({input, setInput, onChange, errors, files, currentLocationEditable = false}) {

    return (        
    <>
        <TextField
            label="Session Group Notes"
            name="sessionGroup.notes"
            value={input?.sessionGroup?.notes}
            onChange={(event) => {
                setInput(i => ({...i, sessionGroup: {...i.sessionGroup, notes: event.target.value}}));
            }}
            errors={errors?.SessionGroup?.Notes} />
                

        <div className="block">
            <div className="columns">
                <div className="column is-6">
                    <div className="field">
                        <label className="label">Session Group Location</label>
                        <div className="select">
                            <select
                                value={input.locationEditType}
                                onChange={onChange("locationEditType")}
                            >
                                <option value="current">Current Location</option>
                                <option value="existing">Another Location</option>
                                <option value="new">New Location</option>
                            </select>
                        </div>                
                    </div>
                </div>
            </div>
        </div>
        

        {input.locationEditType === "current" && currentLocationEditable && (
        <LocationFields input={input} setInput={setInput} errors={errors} locationFieldName="location" />
        )}

        {input.locationEditType === "existing" && (
            <TextField
                label="Location Id"
                name="Location Id"
                value={input?.locationId}
                onChange={(event) => {
                    setInput(i => ({ ...i, locationId: event.target.value }));
                }}
                errors={errors?.LocationId} />
        )}

        {input.locationEditType === "new" && (
        <LocationFields input={input} setInput={setInput} errors={errors} locationFieldName="newLocation" />
        )}


        <div className="block">
            <div className="columns">
                <div className="column is-6">
                    <div className="field">
                        <label className="label">File</label>
                        <div className="select">
                            <select
                                value={input.fileId}
                                onChange={onChange("fileId")}
                            >
                            {files.items.map(f => {
                                return (<option key={f.id} value={f.id}>{f.filename}</option>);
                            })}
                                
                            </select>
                        </div>
                        <FieldErrors errors={errors?.FileId} />
                    </div>
                </div>               
            </div>
        </div>
        
    </>
    )

}


function LocationFields({ input, setInput, errors, locationFieldName })
{
    return (
    <div className="block">
        <div className="columns">                
            <div className="column is-12">
                <TextField
                    label="Venue"
                    name="venue"
                    value={input?.[locationFieldName]?.venue}
                    onChange={(event) => {
                        setInput(i => ({...i, [locationFieldName]: {...i[locationFieldName], venue: event.target.value}}));
                    }}
                    errors={errors?.LocationVenue}
                />

                <TextField
                    label="City"
                    name="city"
                    value={input?.[locationFieldName]?.city}
                    onChange={(event) => {
                        setInput(i => ({...i, [locationFieldName]: {...i[locationFieldName], city: event.target.value}}));
                    }}
                    errors={errors?.LocationCity}
                />

                <TextField
                    label="Country"
                    name="country"
                    value={input?.[locationFieldName]?.country}
                    onChange={(event) => {
                        setInput(i => ({...i, [locationFieldName]: {...i[locationFieldName], country: event.target.value}}));
                    }}
                    errors={errors?.LocationCountry}
                />                   
            </div>
        </div>
    </div>
    )
}


