import { useEffect, React } from "react";
import { useMsalAuthentication, useMsal } from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from './msal/msalConfig';

export function Login() {

    const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Popup, loginRequest);
        }
    }, [error, login]);

    const { instance, accounts, inProgress } = useMsal();

    if (accounts.length > 0) {
        return (
          <div>
            <div className="notification is-success">Signed in!</div>

            <button className="button" onClick={() => instance.logout()}>Logout</button>
          </div>
        )
    } else if (inProgress === "login") {
        return (
          <div className="notification is-info">Login is currently in progress!</div>
        )
    } else {
      return (
          <div>
            <div className="notification is-danger">
                There are currently no users signed in!              
            </div>

            <button className="button" onClick={() => instance.loginPopup(loginRequest)}>Login</button>
          </div>
      );
    }
}