import { useEffect} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {  
    if (window.ga)  {
      ReactGA.pageview(location.pathname + location.search);
    }
    else {
      console.warn("GA not init")
    }
  }, [location]);
};

export default usePageTracking;