

export function SendFeedback(feedback) {
        
    return fetch(`${process.env.REACT_APP_API_URL}/api/feedback`, {
        body: JSON.stringify(feedback),
        headers: {
            'Content-Type': 'application/json'            
          },
        method: "POST"
    })
}