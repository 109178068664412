import { useEffect } from 'react';
import { bodyClass } from "./bodyClass";

export function Resources() {
    
    useEffect(() => {
        bodyClass('resources')
    }, []);

    return (
        <>
        <h1 className="title  is-spaced">Resources</h1>
        <h2 className="subtitle">
            All links will open in a separate browser tab or window.
        </h2>

        <ol>
        <li>
            Articles written by John Milmo
            <ul className="dots">
                <li>
                    A very helpful and complete article about <a href="./PDF/John Milmo - HMV and its variants - revised.pdf" target="_blank">the matrix number 
                    system used by HMV and <br />The Gramophone Company during the 78 rpm era.</a>
                </li>
                <li><a href="./PDF/Introductions/HMV Serial and Face numbers.pdf" target="_blank">HMV Serial and Face numbers--a brief note</a></li>
                <li><a href="./PDF/Introductions/1921-1934 RECORDING LOCATIONS.pdf" target="_blank">Recording Locations, 1921-1934</a></li>
                <li><a href="./PDF/Introductions/ab INTRODUCTORY NOTE BY JM.pdf" target="_blank">Suf-ab Introductory Note</a></li>
                <li><a href="./PDF/Introductions/abbreviations.pdf" target="_blank">Abbreviations and Conventions</a></li>
            </ul>
        </li>

        <li>            
            <a href="https://en.wikipedia.org/wiki/Alan_Kelly_(discographer)" target="_blank"rel="noreferrer" >Wikipedia article about Dr. Kelly</a>
        </li>
        <li>
            <a href="http://www.emiarchivetrust.org" target="_blank" rel="noreferrer">Website of the EMI Archive Trust</a>
        </li>       

        <li>Introductory articles written by Alan Kelly
            <ul className="dots">
                <li><a href="./PDF/Introductions/General Introduction.pdf" target="_blank">General Introduction</a></li>
                <li><a href="./PDF/Introductions/MAT101-Introduction.pdf" target="_blank">Early Matrix Series, 1898 to 1904</a></li>
                <li><a href="./PDF/Introductions/MAT102 File A -Introduction.pdf" target="_blank">Suffixes a, b, c, recorded by Frederick William Gaisberg et al, 1900 to 1919</a></li>
                <li><a href="./PDF/Introductions/MAT103 File A - Introduction.pdf" target="_blank">Suffixes d, e, f, recorded by William Conrad Gaisberg, et al, 1903-1921</a></li>
                <li><a href="./PDF/Introductions/SUF ghi Intro and Locations Final.pdf" target="_blank">Suffixes g/h/i(j) and A(D) and Locations</a></li>
                <li><a href="./PDF/Introductions/MAT105 File A - Introduction.pdf" target="_blank">Suffixes k, l, m (early use C, z, Hp)</a></li>
                <li><a href="./PDF/Introductions/MAT106 File A -INTRODUCTION.pdf" target="_blank">Suffixes n, o, p (early use F) and sm</a></li>
                <li><a href="./PDF/Introductions/MAT107 File A - -Introduction.pdf" target="_blank">Suffixes q, r, s</a></li>
                <li><a href="./PDF/Introductions/MAT108 File A - -Introduction.pdf" target="_blank">Suffixes t, u, v</a></li>
                <li><a href="./PDF/Introductions/MAT109 File A - -Introduction.pdf" target="_blank">Suffixes x, y, z</a></li>
                <li><a href="./PDF/Introductions/MAT115 File A - Introduction.pdf" target="_blank">Head Office Series, 1912-1921</a></li>
                <li><a href="./PDF/Introductions/MAT201 Introduction.pdf" target="_blank">Head Office Series prefixed BA / CA, 1921-1929 AND Head Office Series prefixed Bb / Cc, 1921-1930.</a></li>
                <li><a href="./PDF/Introductions/MAT204 File A -  Introduction.pdf" target="_blank">Head Office Series prefixed BJ / CJ, 1925-1930 AND Head Office Series prefixed BK / CK, 1921-1930</a></li>
                <li><a href="./PDF/Introductions/MAT205 File A - Introduction.pdf" target="_blank">Head Office Series prefixed BL / CL, 1924-1930 AND Head Office Series prefixed BM / CM, 1921-1930.</a></li>
                <li><a href="./PDF/Introductions/MAT206  File A - -Introduction.pdf" target="_blank">Head Office Series prefixed BN / CN, S E Tunn, 1928-1930 AND Head Office Series prefixed BP / CP, J Jackson, 1923-1926.</a></li>
                <li><a href="./PDF/Introductions/MAT207 File A - Introduction.pdf" target="_blank">Head Office Series prefixed BR / CR, 1912-1921</a></li>
            </ul>
        </li>

        <li>
            <a href="https://adp.library.ucsb.edu/index.php/resources/detail/441" target="_blank" rel="noreferrer">
            His Master’s Voice Celebrity Series Recordings and Historical Reissues, 
            1924‐1958 compiled by John Bolig and John Milmo and available for free download
            </a>
        </li>
        </ol>
        </>
    )
}