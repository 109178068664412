import { useState } from 'react';
import { HelpModalSection } from './HelpModalSection';

export function HelpModal({display, setDisplay}) {

    const displayClass = display ? " is-active" : ""

    const [displaySection, setDisplaySection] = useState("");

    return (<div className={"modal" + displayClass}>
        <div className="modal-background"></div>
        <div className="modal-content">
            <div className="box">

                <h1 className="title">Advanced search help</h1>
                <div className="content">
                    <p>
                        In general when you enter a value in a single field, like this:
                </p>

                    <p>
                        the results include all records that have what you entered ("to be or not to be") somewhere in that field. To limit the search results, use additional search criteria; only records that match ALL criteria are returned.
                </p>


                    <p>For details about a particular search field, expand the relevant section below.</p>
                </div>

                <HelpModalSection title="Performer" section={"performer"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>To retrieve all records by a particular performer, enter the name in the Performer field. Use the shortest form of a distinctive name:</p>
                    <ul>
                        <li>melba</li>
                        <li>gielgud</li>
                        <li>figner</li>
                        <li>royal victoria hotel orchestra</li>
                    </ul>

                    <p>For a common name, use the full form to reduce that number of hits.</p>
                    <ul>
                        <li>arthur smith</li>
                    </ul>

                    <p>Use the advanced search to find entries where more than one performer is listed; e.g., a soloist with orchestra, a duet.</p>
                </HelpModalSection>

                <HelpModalSection title="Title" section={"title"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>The title field in the database contains the title, the composer or author, and the name of the work if what is on the record is part of a larger work.</p>

                    <p>Use the shortest distinctive form of a title or composer's name.</p>
                    <ul>
                        <li>rocked in the cradle of the deep</li>
                        <li>to be or not to be</li>
                        <li>tosti</li>
                        <li>faust</li>
                    </ul>
                    <p>Include punctuation that is in the middle of a title string.</p>
                    <ul>
                        <li>hear ye, israel</li>
                    </ul>
                    <p>Use the advanced search to narrow the search results; e.g., for a title within a larger work, or a work by a particular composer.</p>
                </HelpModalSection>

                <HelpModalSection title="Matrix #" section={"matrix"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>Enter the Matrix number without any prefix or suffix. For matrix numbers less than 5 digits long, precede the number with an equals sign.</p>
                    <ul>
                        <li>12427</li>
                        <li>=2427</li>
                    </ul>
                    <p>For an exact match, combine the Matrix # search with a Prefix and/or Suffix search</p>
                </HelpModalSection>

                <HelpModalSection title="Prefix" section={"prefix"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>Where a matrix number has a prefix, enter it here. It is not necessary to use upper case.</p>
                    <ul>
                        <li>bb</li>
                        <li>2EA</li>
                    </ul>
                </HelpModalSection>

                <HelpModalSection title="Suffix" section={"suffix"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>Where a matrix number has a suffix, enter it here. It is not necessary to use upper case.</p>
                    <ul>
                        <li>ab</li>
                        <li>e</li>
                    </ul>
                </HelpModalSection>

                <HelpModalSection title="Issue Number" section={"issueNumber"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>Include the whole issue number</p>
                    <ul>
                        <li>IP1234</li>
                        <li>2-44326</li>
                        <li>02095</li>
                    </ul>
                    <p>
                        Note that this search will retrieve all records whose issue number field contains the string searched for.
                </p>
                    <p>
                        Preceding the number with an equal sign allows retrieval of that exact number if it is the only number in the issue number field.
                </p>
                    <ul>
                        <li>=02095</li>
                    </ul>

                </HelpModalSection>

                <HelpModalSection title="Recording Date" section={"recordingDate"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>
                        The data string in the Recording Date field is indexed as if it were complete but in many cases it is not. It is possible to search on date only, but it will be most useful to limit searches on other fields such as Performer or Title.
                </p>
                    <p>
                        Enter the full date in the form dd-mm-yy. Include the dashes.
                </p>
                    <ul>
                        <li>08-08-98</li>
                    </ul>

                </HelpModalSection>


                <HelpModalSection title="Location" section={"location"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>Enter the name of a country, city, or town.</p>
                    <ul>
                        <li>russia</li>
                        <li>strasbourg</li>
                        <li>croydon</li>
                        <li>hayes</li>
                    </ul>
                </HelpModalSection>

                <HelpModalSection title="File" section={"file"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>Enter a Kelly file name, including punctuation but not capitalization.</p>
                    <ul>
                        <li>suf-b</li>
                        <li>ba</li>
                    </ul>
                    <p>
                        This search may retrieve more records than can be displayed. It will be most useful to limit searches on another field.
                </p>
                </HelpModalSection>

                <HelpModalSection title="Other" section={"other"} displaySection={displaySection} setDisplaySection={setDisplaySection}>
                    <p>
                        This field is unlikely to be used on its own in a search, as it does not contain a consistent type of data.
                </p>
                    <p>
                        A search in the Title field or in the Issue Number field considers this field when looking for a match.
                </p>
                </HelpModalSection>

            </div>
            <button className="modal-close is-large" aria-label="close" onClick={(event) => { setDisplay(false); }}></button>
        </div>
    </div>);
}
