import { useState } from 'react';
import { TextField } from './TextField';
import { FieldErrors } from './FieldErrors';
import { RecordingSizeInput } from './RecordingSizeInput';
import EditApi from '../Api/EditApi';
import { ErrorMessages } from '../ErrorMessages';

export function AddRecording({display, setLoading, files}) {

    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState(null);

    const submit = () => {
        setLoading(true);

        let body = {
            emailAddress: input.emailAddress,
            suggestionNotes: input.suggestionNotes,
            sessionEditType: input.sessionEditType,
            sessionGroupEditType: input.sessionGroupEditType,
            locationEditType: input.locationEditType,

            recordingDate: input.recordingDate,
            prefix: input.prefix,
            matrixNumber: input.matrixNumber,
            suffix: input.suffix,
            takeNumber: input.takeNumber,
            extraData: input.extraData,
            title1: input.titles[0],
            title2: input.titles[1],
            title3: input.titles[2],
            title4: input.titles[3],
            title5: input.titles[4],
            issue1: input.issues[0],
            issue2: input.issues[1],
            issue3: input.issues[2],
            issue4: input.issues[3],
            issue5: input.issues[4],
            notes: input.notes,
            sizeCode: parseInt(input.sizeCode, 10),
            sessionId: parseInt(input.sessionId, 10),
            artist1: input.session?.artists?.[0],
            artist2: input.session?.artists?.[1],
            artist3: input.session?.artists?.[2],
            artist4: input.session?.artists?.[3],
            artist5: input.session?.artists?.[4],
            sessionNotes: input.session?.notes,
            sessionGroupId: parseInt(input.sessionGroupId, 10),
            sessionGroupNotes: input.sessionGroup?.notes,
            locationId: parseInt(input.locationId, 10),
            locationCity: input.location?.city,
            locationCountry: input.location?.country,
            locationVenue: input.location?.venue,
            fileId: parseInt(input.fileId, 10),
        }
        EditApi.suggestNewRecording(body).then(response => {

            if (!response.ok) {
                console.log("suggestNewRecording response not ok", response.status);

                return response.json().then(errors => {

                    setErrors(errors);
                    setLoading(false);
                }).catch(e => {
                    console.log("error response didn't contain json");
                    throw e;
                });
            }

            console.log("suggestNewRecording sucess");
            setSubmitted(true);
            setErrors(null);
            setLoading(false);

        }).catch(e => {
            setErrors({ "general": ["An error occured. Please try again later."] });
            setLoading(false);
        });
    }

    const onChange = (name) => {
        return (event) => { setInput(i => ({ ...i, [name]: event.target.value })); };
    };

    const [input, setInput] = useState({
        emailAddress: '',
        suggestionNotes: '',
        sessionEditType: "new",
        sessionGroupEditType: "new",
        locationEditType: "new",

        prefix: '',
        matrixNumber: '',
        suffix: '',
        takeNumber: '',
        filename: '',
        recordingDate: '',
        location: {
            venue: '',
            city: '',
            country: ''
        },
        sizeCode: 0,
        titles: [''],
        artists: [''],
        issues: [''],
        other: '',
        sessionId: '',
        sessionGroupId: '',        
        session: {
            artists: ['']
        },
        sessionGroup: {
            notes: ''
        },
        fileId: 0,
    });

    if (!display) {
        return null
    }

    if (submitted) {
        return (
            <>
                <h1 className="title is-spaced">Add New Recording</h1>


                <div className="notification is-success">
                    Thank you for your submission. It will be reviewed shortly.
                </div>
            </>
        );
    }

    return (
        <>
            <h1 className="title is-spaced">Add New Recording</h1>

            <ErrorMessages errors={Object.values(errors ?? {}).flat()} />

            <div className="block">
                <div className="columns">

                    <div className="column is-6">
                        <TextField label="Matrix Number" value={input.matrixNumber} onChange={onChange("matrixNumber")} errors={errors?.MatrixNumber} />

                        <TextField label="Prefix" value={input.prefix} onChange={onChange("prefix")} errors={errors?.Prefix} />

                        <TextField label="Suffix" value={input.suffix} onChange={onChange("suffix")} errors={errors?.Suffix} />
                    </div>

                    <div className="column is-6">
                        <TextField label="Take Number" value={input.takeNumber} onChange={onChange("takeNumber")} errors={errors?.TakeNumber} />

                        <TextField label="Recording Date" value={input.recordingDate} onChange={onChange("recordingDate")} errors={errors?.RecordingDate} />

                        <RecordingSizeInput value={input.sizeCode} onChange={onChange("sizeCode")} errors={errors?.SizeCode} />
                    </div>
                </div>
            </div>

            <div className="block">
                <div className="columns">
                    <div className="column is-12">

                        {input.titles.map((title, index) => {
                            return (<TextField
                                label={"Title " + (index + 1)}
                                name={"title[" + index + "]"}
                                value={input.titles[index]}
                                onChange={(event) => {
                                    setInput(i => {
                                        let newTitles = [...i.titles];
                                        newTitles[index] = event.target.value;
                                        return ({ ...i, titles: newTitles });
                                    });
                                }}
                                errors={errors?.["Title" + (index + 1)]} />);
                        })}

                        {input.titles.length < 5 && (
                            <button 
                                className="button is-success"                             
                                onClick={(event) => {
                                    setInput(i => {
                                        let newTitles = [...i.titles, ''];                                        
                                        return ({ ...i, titles: newTitles });
                                    });
                                }}
                            >Add Title</button>
                        )}
                    </div>
                </div>
            </div>

            <div className="block">
                <div className="columns">
                    <div className="column is-12">

                        {input.issues.map((issue, index) => {
                            return (<TextField
                                label={"Issue Number " + (index + 1)}
                                name={"issues[" + index + "]"}
                                value={input.issues[index]}
                                onChange={(event) => {
                                    setInput(i => {
                                        let newIssues = [...i.issues];
                                        newIssues[index] = event.target.value;
                                        return ({ ...i, issues: newIssues });
                                    });
                                }}
                                errors={errors?.["Issue" + (index + 1)]} />);
                        })}

                        {input.issues.length < 5 && (
                            <button 
                                className="button is-success"
                                onClick={(event) => {
                                    setInput(i => {
                                        let newIssues = [...i.issues, ''];
                                        return ({ ...i, issues: newIssues });
                                    });
                                }}
                            >Add Issue Number</button>
                        )}
                    </div>
                </div>
            </div>

            <hr />

            <div className="block">
                <div className="columns">
                    <div className="column is-12">
                        <div className="field">
                            <label className="label">Session</label>
                            <div className="select">
                                <select
                                    value={input.sessionEditType}
                                    onChange={onChange("sessionEditType")}
                                >
                                    <option value="existing">Use Existing Session</option>
                                    <option value="new">Use New Session</option>
                                </select>
                            </div>                
                        </div>
                    </div>
                </div>
            </div>

            <div className="block">
                <div className="columns">
                    <div className="column is-12">
                        {input.sessionEditType === "existing" && (
                        <TextField
                            label="Session Id"
                            name="Session Id"
                            value={input?.sessionId}
                            onChange={(event) => {
                                setInput(i => ({ ...i, sessionId: event.target.value }));
                            }}
                            errors={errors?.SessionId} />
                        )}

                        {input.sessionEditType === "new" && (
                            
                        <>

                        <TextField
                            label="Notes"
                            name="notes"
                            value={input?.session?.notes}
                            onChange={(event) => {
                                setInput(i => ({...i, session: {...i.session, notes: event.target.value}}));
                            }}
                            errors={errors?.SessionNotes} />  

                        {input.session.artists.map((artist, index) => {
                            return (<TextField
                                label={"Artist " + (index + 1)}
                                name={"artists[" + index + "]"}
                                value={input.session.artists[index]}
                                onChange={(event) => {
                                    setInput(i => {
                                        let newArtists = [...i.session.artists];
                                        newArtists[index] = event.target.value;
                                        return ({ ...i, session: {...i.session, artists: newArtists}});
                                    });
                                }}
                                errors={errors?.["Artist" + (index + 1)]} />);
                        })}

                        {input.session.artists.length < 5 && (
                            <button 
                                className="button is-success mb-5"
                                onClick={(event) => {
                                    setInput(i => {
                                        let newArtists = [...i.session.artists, ''];
                                        return ({ ...i, session: {...i.session, artists: newArtists}});
                                    });
                                }}
                            >Add Artist</button>
                        )}                        
                                              
                        </>
                        )}
                    </div>
                </div>
            </div>

            <hr />

            {input.sessionEditType === "new" && (
            <>
            
                <div className="block">
                    <div className="columns">
                        <div className="column is-6">
                            <div className="field">
                                <label className="label">Session Group</label>
                                <div className="select">
                                    <select
                                        value={input.sessionGroupEditType}
                                        onChange={onChange("sessionGroupEditType")}
                                    >
                                        <option value="existing">Use Existing Session Group</option>
                                        <option value="new">Use New Session Group</option>
                                    </select>
                                </div>                
                            </div>
                        </div>
                        <div className="column is-6">
                        {input.sessionGroupEditType === "existing" && (
                        <TextField
                            label="Session Group Id"
                            name="Session Group Id"
                            value={input?.sessionGroupId}
                            onChange={(event) => {
                                setInput(i => ({ ...i, sessionGroupId: event.target.value }));
                            }}
                            errors={errors?.SessionGroupId} />
                        )}

                        {input.sessionGroupEditType === "new" && (
                        <TextField
                            label="Notes"
                            name="sessionGroup.notes"
                            value={input?.sessionGroup?.notes}
                            onChange={(event) => {
                                setInput(i => ({...i, sessionGroup: {...i.sessionGroup, notes: event.target.value}}));
                            }}
                            errors={errors?.SessionGroupNotes} />                        
                        )}
                        </div>
                    </div>
                </div>

                {input.sessionGroupEditType === "new" && (
                <>
                <div className="block">
                    <div className="columns">
                        <div className="column is-6">
                            <div className="field">
                                <label className="label">Location</label>
                                <div className="select">
                                    <select
                                        value={input.locationEditType}
                                        onChange={onChange("locationEditType")}
                                    >
                                        <option value="existing">Use Existing Location</option>
                                        <option value="new">Use New Location</option>
                                    </select>
                                </div>                
                            </div>
                        </div>
                        <div className="column is-6">
                        {input.locationEditType === "existing" && (
                            <TextField
                                label="Location Id"
                                name="Location Id"
                                value={input?.locationId}
                                onChange={(event) => {
                                    setInput(i => ({ ...i, locationId: event.target.value }));
                                }}
                                errors={errors?.LocationId} />
                        )}

                        {input.locationEditType === "new" && (
                            <>
                            <TextField
                                label="Venue"
                                name="venue"
                                value={input?.location?.venue}
                                onChange={(event) => {
                                    setInput(i => ({...i, location: {...i.location, venue: event.target.value}}));
                                }}
                                errors={errors?.LocationVenue}
                            />

                            <TextField
                                label="City"
                                name="city"
                                value={input?.location?.city}
                                onChange={(event) => {
                                    setInput(i => ({...i, location: {...i.location, city: event.target.value}}));
                                }}
                                errors={errors?.LocationCity}
                            />

                            <TextField
                                label="Country"
                                name="country"
                                value={input?.location?.country}
                                onChange={(event) => {
                                    setInput(i => ({...i, location: {...i.location, country: event.target.value}}));
                                }}
                                errors={errors?.LocationCountry}
                            />
                            </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="block">
                    <div className="columns">
                        <div className="column is-6">
                            <div className="field">
                                <label className="label">File</label>
                                <div className="select">
                                    <select
                                        value={input.fileId}
                                        onChange={onChange("fileId")}
                                    >
                                    {files.items.map(f => {
                                        return (<option key={f.id} value={f.id}>{f.filename}</option>);
                                    })}
                                        
                                    </select>
                                </div>
                                <FieldErrors errors={errors?.FileId} />
                            </div>
                        </div>               
                    </div>
                </div>
                </>
                )}
            </>
            )}

            <hr />

            <TextField label="Your Email Address" value={input.emailAddress} onChange={onChange("emailAddress")} errors={errors?.EmailAddress} />

            <TextField label="Reason for Change" value={input.suggestionNotes} onChange={onChange("suggestionNotes")} errors={errors?.SuggestionNotes} />

            <div className="field is-grouped">
                <div className="control">
                    <button className="button is-link" onClick={(event) => { submit(); }}>Suggest New Recording</button>
                </div>
            </div>
        </>
    );
}
