// import { React } from "react";
import { useMsal } from "@azure/msal-react";

function useMsalAccount() {

    const { accounts } = useMsal();

    var umusicAccount = accounts.find(p => p?.username?.toLowerCase()?.endsWith('@umusic.com'));

    return { account: umusicAccount }
}

function RoleTemplate({role, children}) {

    const { account } = useMsalAccount();

    if ((account ?? null) === null) {
        console.log("RoleTemplate account is null");
        return null
    }

    if ((role ?? null) === null) {
        console.log("RoleTemplate role is null");
        return null;
    }
    
    if (account?.idTokenClaims?.roles?.includes(role)) {        
        return children
    }

    console.log(account?.idTokenClaims?.roles)
    console.log("contains role", account?.idTokenClaims?.roles?.includes(role))
    console.log("role", role)
   
    return null
}

export { RoleTemplate }