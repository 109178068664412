import { useEffect, useState } from 'react';
import { bodyClass } from "./bodyClass";
import { SendFeedback } from './Api/FeedbackApi'


export function Feedback() {

    const [sent, setSent] = useState(false)

    useEffect(() => {
        bodyClass('feedback')
    }, []);

    return (
        <>
        <h1 className="title is-spaced">Feedback</h1>
        <h2 className="subtitle">
            <FeedbackSubtitle sent={sent} />
        </h2>

        
        <FeedbackForm sent={sent} setSent={setSent} />
        
        </>
    )
}

function FeedbackSubtitle({sent}) {
    
    if (sent) {
        return "Thank you for your feedback."
    }

    return "We welcome your comments about the database and website and encourage you to point out any corrections or additions that could be made to the data."
}

function FeedbackForm({sent, setSent}) {

    const [inputs, setInputs] = useState({ name: '', email: '', subject: '', comment: '' })

    if (sent) {
        return null;
    }

    const send = () => {
        let feedback = 
        {
            name: inputs.name,
            email:  inputs.email,
            subject:  inputs.subject,
            comment:  inputs.comment,
        }
        SendFeedback(feedback).then((response) => {
            setSent(true);
        })

    }

    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
    };



    return (
    <>        
        <div className="fields">
            <div className="field">
                <label className="label">Full name</label>
                <div className="control">
                    <input className="input" type="text" name="name" value={inputs.name}  onChange={handleInputChange} />
                </div>
            </div>

            <div className="field">
                <label className="label">Email</label>
                <div className="control">
                    <input className="input" type="text" name="email" value={inputs.email}  onChange={handleInputChange} />
                </div>
            </div>

            <div className="field">
                <label className="label">Subject</label>
                <div className="control">
                    <input className="input" type="text" name="subject" value={inputs.subject}  onChange={handleInputChange} />
                </div>
            </div>

            <div className="field">
                <label className="label">Comment</label>
                <div className="control">
                    <textarea className="textarea" name="comment" value={inputs.comment} onChange={handleInputChange} />
                </div>
            </div>

            <button className="button is-secondary" onClick={(event) => { send() }}>Send</button>
        </div>
        </>
    )
}