import { useState, useEffect } from 'react';
import { bodyClass } from "../bodyClass";
import { SearchResults } from "../SearchResults";
import { useHistory } from 'react-router-dom';
import { useQuery } from '../Home';
import { Loading } from '../Loading';
import { search } from '../Api/RecordingApi'

export function AdvancedSearchResults() {

    useEffect(() => {
        bodyClass('advancedsearchresults');
    }, []);

    const history = useHistory();
    const query = useQuery();

    const [results, setResults] = useState(history?.location?.state?.results || null);

    let searchCriteria = Object.fromEntries(query);

    console.log('results', results);
    console.log('searchCriteria', searchCriteria);

    useEffect(() => {

        if (results == null) {            
            return search(searchCriteria)
                .then(response => response.json())
                .then(data => {
                    setResults(data);
                    // history.replace(history.location.pathname + history.location.search, { results: data });
                })
                .catch(error => {
                    setResults([])
                });
        }

    }, [searchCriteria, results, history]);


    return (
        <>
            <Loading display={results == null} />
            <SearchResults                
                searchCriteria={searchCriteria}
                results={results}
                totalCount={results?.length} />
        </>
    );


}
