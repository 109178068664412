import { FieldErrors } from './FieldErrors';


export function TextField({ label, value, onChange, errors, disabled, after}) {

    let inputClassName = ((errors?.length ?? 0) > 0) ? "input is-danger" : "input";

    // after={(
    //     <p class="control">
    //         <label className="label">&nbsp;</label>
    //         <a class="button is-info">
    //         Search
    //         </a>
    //     </p>
    // )}

    return (
        <div className="field is-grouped">
            <div className="control is-expanded">
                <label className="label">{label}</label>
                <input disabled={disabled} className={inputClassName} type="text" placeholder="" value={value ?? ""} onChange={onChange} />
                <FieldErrors errors={errors} />
            </div>

            {after}
            
        </div>
    );
}
