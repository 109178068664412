import React, { useState, useEffect } from 'react';
import PaginationFirst from './images/pagination-first.svg';
import PaginationPrev from './images/pagination-prev.svg';
import PaginationNext from './images/pagination-next.svg';
import PaginationLast from './images/pagination-last.svg';
import { searchExportLink } from './Api/RecordingApi'
import { NavLink } from 'react-router-dom';

export function SearchResults({results, searchCriteria}) {
    const unselectedRowIndex = -1;
    const [selectedRow, setSelectedRow] = useState(unselectedRowIndex);    
    const [pagination, setPagintion] = useState({ offset: 0, size: 150});
    const [paginatedResults, setPaginatedResults] = useState(null);
    const [exportUrl, setExportUrl] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    
    useEffect(() => {
        setExportUrl(searchExportLink(searchCriteria))
    }, [searchCriteria])

    
    useEffect(() => {

        let sortedItems = results?.items;

        if (sortBy != null)
        {
            console.debug('soring by column', sortBy.column)
            console.debug('soring by column', sortBy.desc)

            let sortFunc = sortBy.desc ? (itemA, itemB) => { return itemA[sortBy.column] > itemB[sortBy.column] ? -1 : 1 }: 
                                    (itemA, itemB) => { return itemA[sortBy.column] < itemB[sortBy.column] ? -1 : 1 };

            sortedItems = sortedItems?.sort(sortFunc);            

            console.debug(sortedItems[0])
        }

        setPaginatedResults(sortedItems?.slice(pagination.offset, pagination.offset + pagination.size))
    }, [results, sortBy, pagination])
        
    
    if (results === null) {
        return null
    }


    let start = pagination.offset + 1
    let end = pagination.offset + pagination.size
    let resultCount = results.items?.length ?? 0
    
    if (end > resultCount) {
        end = resultCount
    }

    let firstPageOffset = 0
    let prevPageOffset = pagination.offset - pagination.size
    let nextPageOffset = pagination.offset + pagination.size
    let lastPageOffset = resultCount - (resultCount % pagination.size === 0 ? pagination.size : resultCount % pagination.size)
    
    let nextDisabled = (pagination.offset + pagination.size) >= resultCount
    let prevDisabled = pagination.offset <=0   

    let title = (<h1 className="title is-spaced">Showing {resultCount?.toLocaleString()} of {results?.total?.toLocaleString()} results for <em>{results?.criteriaDescription}</em></h1>)

    if (resultCount <= 0) {
        return title
    }

    const handleSortChange = (column) => {
        setSortBy(c => { 
            return { 
                column: column, 
                desc: (column === c?.column) ? !c.desc : false 
            };
            }); 
    }   
    
    return (
        <>
        {title}
        <h2 className="subtitle">Click any entry to see the details. 
        The column entitled 'Source' in the search results shows whether the data was derived from Dr. Kelly's files (K) or from the HMV Celebrity Series Listing (C).  IF K, a PDF is available in the specific search result that takes you to a copy of the specific Kelly file.  If (C), there is a link on the Resources page of the website that allows you to download a copy of that listing
        </h2>
        <table className="table results">
            <thead>
                <tr>
                    <td>Source</td>
                    <td className="sortable" onClick={(event) => { event.preventDefault(); handleSortChange('prefix'); }}>Prefix</td>
                    <td className="sortable" onClick={(event) => { event.preventDefault(); handleSortChange('matrixNumber'); }}>Matrix #</td>
                    <td>Take</td>
                    <td className="sortable" onClick={(event) => { event.preventDefault(); handleSortChange('suffix'); }}>Suffix</td>
                    <td className="sortable" onClick={(event) => { event.preventDefault(); handleSortChange('artists'); }}>Performer (s)</td>
                    <td className="sortable" onClick={(event) => { event.preventDefault(); handleSortChange('titles'); }}>Title</td>
                    <td className="sortable" onClick={(event) => { event.preventDefault(); handleSortChange('recordingDate'); }}>Recording date</td>
                    <td className="sortable" onClick={(event) => { event.preventDefault(); handleSortChange('issueNumber'); }}>Issue numbers</td>
                </tr>
            </thead>
            <tbody>
                {paginatedResults?.map((row, index) => { 
                let offsetIndex = pagination.offset + index
                let isSelected = selectedRow === offsetIndex;
                let rowClass = isSelected ? "selected" : "";
                let pdfUrl = `./PDF/${row.source}.pdf`
                let source = row.source === "Celebrity Series" ? "C" : "K"
                let editUrl = `./edit/${row.id}`

                let locationDisplay = [row.location.venue, row.location.city, row.location.country].filter(l => ((l ?? "") !== "")).join(", ")

                return (
                <React.Fragment key={index}>
                    <tr className={rowClass} onClick={(event) => { setSelectedRow((x) => {return (x === offsetIndex) ? unselectedRowIndex : offsetIndex; }); }}>
                        <td>{source}</td>
                        <td>{row.prefix}</td>
                        <td>{row.matrixNumber}</td>
                        <td>{row.takeNumber}</td>
                        <td>{row.suffix}</td>
                        <td>{row.artists?.[0]}</td>
                        <td>{row.titles?.[0]}</td>
                        <td>{row.recordingDate}</td>
                        <td>{row.issuedNumbers?.[0]}</td>
                    </tr>
                    {isSelected && 
                    <tr className="detail">                        
                        <td colSpan="9">
                            <div className="columns is-align-content-flex-center" >
                                <div className="column ml-2 is-flex-grow-2">
                                    <div className="m-2"><strong>Kelly File: </strong>{row.source}</div>
                                    {/* <div className="m-2"><strong>Recordings in file: </strong>{row.recordingNumber}</div> */}
                                    <div className="m-2"><strong>Location: </strong>{locationDisplay}</div>
                                    <div className="m-2"><strong>Recording date: </strong>{row.recordingDate}</div>
                                    <div className="m-2"><strong>Other: </strong>{row.other}</div>
                                </div>
                                <div className="column is-flex-grow-4">
                                    <div className="m-2"><strong>Title(s): </strong>{row.titlesCombined}</div>
                                    <div className="m-2"><strong>Issued: </strong>{row.issuedNumbersCombined}</div>
                                    <div className="m-2"><strong>Size: </strong>{row.size}</div>
                                    <div className="m-2"><strong>Session Performer(s): </strong>{row.artistsCombined}</div>
                                    <div className="m-2"><strong>Recording Notes: </strong>{row.notes}</div>
                                </div>
                                <div className="column is-align-self-center is-flex-grow-0">                                
                                    
                                        { source !== "C" && (
                                        <a className="button is-secondary m-4 is-flex" href={pdfUrl} target="_blank" rel="noreferrer">Download PDF</a>                                    
                                        )}

                                        <NavLink className="button is-secondary m-4  is-flex" to={editUrl}>Suggest Edit</NavLink>                                    
                                </div>
                            </div>
                            <div>
                                <div className="ml-2">
                                    <span className="m-2"><strong>Session Id: </strong>{row.sessionId}</span>
                                    <span className="m-2"><strong>Session Group Id: </strong>{row.sessionGroupId}</span>
                                    <span className="m-2"><strong>Location Id: </strong>{row.location.id}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    }
                </React.Fragment>
                )})}
            </tbody>
        </table>

        
        <div className="columns" style={{alignItems: 'center'}}>
            <div className="column" style={{flexGrow: 2}}>
                <a href={exportUrl} target="_blank" rel="noreferrer">
                    <button type="button" className="button is-secondary">Download all results</button>
                </a>
            </div>

            <div className="column" style={{flexGrow: 5}}>
            
                <div className="pagination columns">

                    <div className="column" style={{flexGrow: 2}}>
                        Rows per page
                    </div>

                    <div className="column">
                        <div className="select">
                            <select value={pagination.size} onChange={(event) => { setPagintion(p => { return { offset: 0, size: parseInt(event.target.value, 10)}}) }}>
                                <option value={150}>150</option>
                                <option value={300}>300</option>
                                <option value={500}>500</option>
                                <option value={1000}>1000</option>
                            </select>
                        </div>
                    </div>
                    
                    <div className="column" style={{flexGrow: 2}}>
                    {start}-{end} of {resultCount}
                    </div>
                
                    <div className="column">
                        <button 
                            type="button"
                            className="button is-white" 
                            disabled={prevDisabled} 
                            onClick={(event) => { setPagintion(p => { return { ...p, offset: firstPageOffset}})}}
                        >
                                <img src={PaginationFirst} alt="First Page"/>
                        </button>
                    </div>
                    
                    <div className="column">         
                        <button 
                            type="button"
                            className="button is-white" 
                            disabled={prevDisabled} 
                            onClick={(event) => { setPagintion(p => { return { ...p, offset: prevPageOffset}})}}
                        >
                            <img src={PaginationPrev} alt="Prev Page" />
                        </button>            
                    </div>
                    
                    <div className="column">
                        <button 
                            type="button"
                            className="button is-white" 
                            disabled={nextDisabled}
                            onClick={(event) => { setPagintion(p => { return { ...p, offset: nextPageOffset}})}}
                        >
                            <img src={PaginationNext} alt="Next Page" />
                        </button>            
                    </div>
                    
                    <div className="column">
                        <button 
                            type="button"
                            className="button is-white" 
                            disabled={nextDisabled} 
                            onClick={(event) => { setPagintion(p => { return { ...p, offset: lastPageOffset}})}}>
                            <img src={PaginationLast} alt="Last Page" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        </>
    )
}