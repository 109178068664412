import { useState, useEffect } from 'react';

import EditApi from '../Api/EditApi';

import { ErrorMessages } from '../ErrorMessages';

import { TextField } from './TextField';
import { RecordingSizeInput } from './RecordingSizeInput';

export function EditRecording({display, recording, setLoading}) {

    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState(null);
    
    const [input, setInput] = useState({        
        prefix: '',
        matrixNumber: '',
        suffix: '',
        takeNumber: '',
        filename: '',
        recordingDate: '',
        size: '',
        titles: [''],
        issues: [''],
        other: '',   
        emailAddress: '',
        suggestionNotes: '',        
    });

    useEffect(() => {
        
        let titles = [recording?.title ?? '', recording?.title2 ?? '', recording?.title3 ?? '', recording?.title4 ?? '', recording?.title5 ?? ''].filter(t => t != null && t !== '');

        if (titles.length < 1) {
            titles.push('');
        }

        let issues = recording?.issues?.map(x => x?.issueNumber ?? '') ?? []
        
        if (issues.length < 1) {
            issues.push('');
        }

        console.log("issues", issues)

        setInput({
            id: recording?.id ?? '',
            prefix: recording?.prefix ?? '',
            matrixNumber: recording?.matrixNumber ?? '',
            suffix: recording?.suffix ?? '',
            takeNumber: recording?.takeNumber ?? '',            
            recordingDate: recording?.recordingDate ?? '',
            sizeCode: recording?.sizeCode ?? 0,            
            titles: titles,
            issues: issues, 
            other: recording?.other ?? '',
            emailAddress: ''
        });

    }, [recording]);

    const submit = () => {
        setLoading(true);
        var body = {
            recordingId: recording.id,
            recordingDate: input.recordingDate,
            prefix: input.prefix,
            matrixNumber: input.matrixNumber,
            suffix: input.suffix,
            takeNumber: input.takeNumber,
            extraData: input.extraData,
            title1: input.titles?.[0] ?? null,
            title2: input.titles?.[1] ?? null,
            title3: input.titles?.[2] ?? null,
            title4: input.titles?.[3] ?? null,
            title5: input.titles?.[4] ?? null,
            notes: input.notes,
            sizeCode: input.sizeCode,
            issue1: input.issues?.[0] ?? null,
            issue2: input.issues?.[1] ?? null,
            issue3: input.issues?.[2] ?? null,
            issue4: input.issues?.[3] ?? null,
            issue5: input.issues?.[4] ?? null,
            emailAddress: input.emailAddress,
            suggestionNotes: input.suggestionNotes
        }
        
        EditApi.suggestEditRecording(body).then(response => {

            if (!response.ok) {
                console.log("submitEdit response not ok", response.status);

                return response.json().then(errors => {

                    setErrors(errors);
                    setLoading(false);
                }).catch(e => {
                    console.log("error response didn't contain json");
                    throw e;
                });
            }

            console.log("recording edited");
            setSubmitted(true);
            setErrors(null);
            setLoading(false);

        }).catch(e => {
            setErrors({ "general": ["An error occured. Please try again later."] });
            setLoading(false);
        });
    };

    if (submitted) {
        return (
            <>
                <h1 className="title is-spaced">Edit Recording</h1>


                <div className="notification is-success">
                    Thank you for your submission. It will be reviewed shortly.
                </div>
            </>
        );
    }

    const onChange = (name) => {
        return (event) => { setInput(i => ({ ...i, [name]: event.target.value })); };
    };


    if (!display) {
        return null
    }

    return (
        <>

            <h1 className="title is-spaced">Edit Recording</h1>

            <ErrorMessages errors={Object.values(errors ?? {}).flat()} />
            
            
            <>

                <div className="block">
                    <div className="columns">

                        <div className="column is-6">
                            <TextField label="Matrix Number" value={input.matrixNumber} onChange={onChange("matrixNumber")} errors={errors?.MatrixNumber} />

                            <TextField label="Prefix" value={input.prefix} onChange={onChange("prefix")} errors={errors?.Prefix} />

                            <TextField label="Suffix" value={input.suffix} onChange={onChange("suffix")} errors={errors?.Suffix} />
                        </div>

                        <div className="column is-6">
                            <TextField label="Take Number" value={input.takeNumber} onChange={onChange("takeNumber")} errors={errors?.TakeNumber} />

                            <TextField label="Recording Date" value={input.recordingDate} onChange={onChange("recordingDate")} errors={errors?.RecordingDate} />
                            
                            <RecordingSizeInput value={input.sizeCode} onChange={onChange("sizeCode")} errors={errors?.SizeCode} />
                        </div>
                    </div>
                </div>

                <div className="block">
                    <div className="columns">
                        <div className="column is-12">

                            {input.titles.map((title, index) => {
                                return (<TextField
                                    key={"title[" + index + "]"}
                                    label={"Title " + (index + 1)}
                                    name={"title[" + index + "]"}
                                    value={input.titles[index]}
                                    onChange={(event) => {
                                        setInput(i => {
                                            let newTitles = [...i.titles];
                                            newTitles[index] = event.target.value;
                                            return ({ ...i, titles: newTitles });
                                        });
                                    }}
                                    errors={errors?.["Title" + (index + 1)]} />);
                            })}

                            {input.titles.length < 5 && (
                                <button 
                                    className="button is-success"
                                    onClick={(event) => {
                                        setInput(i => {
                                            let newTitles = [...i.titles, ''];
                                            return ({ ...i, titles: newTitles });
                                        });
                                    }}
                                >Add Title</button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="block">
                    <div className="columns">
                        <div className="column is-12">
                            {input.issues.map((issue, index) => {
                                return (<TextField
                                    key={"issues[" + index + "]"}
                                    label={"Issue Number " + (index + 1)}
                                    name={"issues[" + index + "]"}
                                    value={input.issues[index]}
                                    onChange={(event) => {
                                        setInput(i => {
                                            let newIssues = [...i.issues];                                            
                                            newIssues[index] = event.target.value;
                                            return ({ ...i, issues: newIssues });
                                        });
                                    }}
                                    errors={errors?.["Issue" + (index + 1)]} />);
                            })}

                            {input.issues.length < 5 && (
                                <button 
                                    className="button is-success"
                                    onClick={(event) => {
                                        setInput(i => {
                                            let newIssues = [...i.issues, ''];
                                            return ({ ...i, issues: newIssues });
                                        });
                                    }}
                                >Add Issue Number</button>
                            )}
                        </div>
                    </div>
                </div>

                <hr />

                <TextField label="Your Email Address" value={input.emailAddress} onChange={onChange("emailAddress")} errors={errors?.EmailAddress} />

                <TextField label="Reason for Change" value={input.suggestionNotes} onChange={onChange("suggestionNotes")} errors={errors?.SuggestionNotes} />

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" onClick={(event) => { submit(); }}>Suggest Recording Changes</button>
                    </div>
                </div>
            </>
            
        </>
    );
}
