import { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import EditApi from './Api/EditApi'
import { Loading } from './Loading';
import { useMsal } from '@azure/msal-react';

const suggestionTypeNames = {
    "delete_recording": "Delete Recording",
    "move_recording": "Move Recording (Existing Session)",
    "move_recording_new": "Move Recording (New Session)",
    "add_recording": "Add Recording",
    "edit_recording": "Edit Recording",
    "edit_session": "Edit Session"
}

export function Review() {
    
    const {instance, accounts} = useMsal();

    const { id } = useParams();

    const [suggestions, setSuggestions] = useState(null);    

    useEffect(() => {

        var request = {
            account: accounts[0]
        };
        
        instance.acquireTokenSilent(request).then(tokenResponse => {

            EditApi.getSuggestions(tokenResponse.accessToken, id)
                .then(response => {
                    if (!response.ok) {
                        let errorMessage = response.status + " Error retriving changes to review."

                        return { error: errorMessage }
                    }

                    return response.json()
                })
                .then((data) => {

                    console.log("setRecordingEditCollection", data)
                    setSuggestions(data);
                }).catch(error => {

                })
            
        });

        
    }, [id, accounts, instance]);
   
    return (
        <>       
        <h1 className="title is-spaced">Review Changes </h1>

        <Loading display={suggestions === null} />

        {suggestions !== null && suggestions?.items?.length <= 0 && (
            <div className="notification is-warning">  
                No changes to review
            </div>
        )}

        {suggestions !== null && suggestions?.items?.length > 0 && (
        <table className="table results">
            <thead>                
                <tr>
                    <td>Suggestion Type</td>
                    <td>Reason</td>                  
                    <td>Created By</td>                  
                    <td>Created At</td>                  
                </tr>
            </thead>
            <tbody>        
                {suggestions?.items?.map((item, index) => {

                    let suggestionTypeName = suggestionTypeNames[item?.suggestionType]

                    let createdTimestamp = null;
                    
                    if (item?.createdTimestamp ?? null !== null) {
                        createdTimestamp = new Date(item?.createdTimestamp).toLocaleString();
                    }

                    return (
                        
                        <tr>                            
                            <td><NavLink exact={true} to={`/review/${item?.id}`}>{suggestionTypeName}</NavLink></td>
                            <td><NavLink exact={true} to={`/review/${item?.id}`}>{item?.suggestionNotes}</NavLink></td>
                            <td><NavLink exact={true} to={`/review/${item?.id}`}>{item?.emailAddress}</NavLink></td>
                            <td><NavLink exact={true} to={`/review/${item?.id}`}>{createdTimestamp}</NavLink></td>                            
                        </tr>
                    )
                })}        
            </tbody>
        </table>
        )}

        </>
    )

}

